// components/cards/Card.js

import React from 'react';
import {
  MapPin,
  Navigation,
  Link as LinkIcon,
  Bookmark,
  Share2,
} from 'lucide-react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

const CardSkeleton = ({ type = 'knowledge', viewMode = 'grid' }) => {
  const renderKnowledgeGraphSkeleton = () => (
    <div className="flex flex-col h-full bg-transparent border border-black rounded-lg overflow-hidden">
      <div className="flex p-4">
        <div className="w-32 h-32 bg-gray-200 rounded-lg animate-pulse flex-shrink-0" />
        <div className="flex-grow ml-4">
          <div className="flex justify-between items-start">
            <div>
              <div className="h-6 w-48 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 w-24 bg-gray-200 rounded animate-pulse mt-2" />
            </div>
            <div className="h-4 w-32 bg-gray-200 rounded animate-pulse" />
          </div>
          <div className="h-4 w-full bg-gray-200 rounded animate-pulse mt-4" />
          <div className="h-4 w-2/3 bg-gray-200 rounded animate-pulse mt-2" />
          <div className="grid grid-cols-2 gap-4 mt-4">
            {[1, 2, 3, 4].map((item) => (
              <div key={item} className="border-b border-black pb-2">
                <div className="h-3 w-24 bg-gray-200 rounded animate-pulse" />
                <div className="h-4 w-32 bg-gray-200 rounded animate-pulse mt-1" />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderPlaceSkeleton = () => (
    <div className="flex flex-col h-full bg-transparent border border-black rounded-lg overflow-hidden">
      <div className="p-4">
        <div className="flex items-start justify-between">
          <div className="flex items-start">
            <div className="w-5 h-5 bg-gray-200 rounded animate-pulse mt-1" />
            <div className="ml-2">
              <div className="h-5 w-48 bg-gray-200 rounded animate-pulse" />
              <div className="h-4 w-64 bg-gray-200 rounded animate-pulse mt-2" />
            </div>
          </div>
          <div className="w-5 h-5 bg-gray-200 rounded animate-pulse" />
        </div>
      </div>
    </div>
  );

  switch (type) {
    case 'knowledge':
      return renderKnowledgeGraphSkeleton();
    case 'place':
      return renderPlaceSkeleton();
    default:
      return null;
  }
};

const Card = ({
  type = 'knowledge',
  data,
  onBookmark,
  onShare,
  onGetDirections,
  onRelatedSearch,
  isBookmarked = false,
  viewMode = 'grid',
  location = null,
}) => {
  const getFormattedDate = (dateString) => {
    try {
      return format(new Date(dateString), 'MMM dd, yyyy');
    } catch (error) {
      return '';
    }
  };

  const renderKnowledgeGraph = (data) => {
    if (!data) return null;

    return (
      <div className="flex flex-col h-full bg-transparent border border-black rounded-lg overflow-hidden">
        <div className="flex p-4">
          {data.imageUrl && (
            <div className="flex-shrink-0">
              <img
                src={data.imageUrl}
                alt={data.title || 'Image'}
                className="w-32 h-32 object-cover rounded-lg"
              />
            </div>
          )}
          <div className="flex-grow ml-4">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-xl font-bold text-black">
                  {data.title}
                </h2>
                <p className="text-sm text-black">{data.type}</p>
              </div>
              {location && (
                <div className="flex items-center text-sm text-black">
                  <MapPin className="w-4 h-4 mr-1" />
                  <span>{location}</span>
                </div>
              )}
            </div>
            {data.website && (
              <a
                href={data.website}
                className="flex items-center text-black hover:text-gray-600 text-sm mt-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkIcon className="w-4 h-4 mr-1" />
                Visit Website
              </a>
            )}
            <p className="mt-2 text-black">{data.description}</p>
            {data.descriptionSource && (
              <a
                href={data.descriptionLink}
                className="text-black hover:text-gray-600 text-sm"
                target="_blank"
                rel="noopener noreferrer"
              >
                - {data.descriptionSource}
              </a>
            )}
            {data.attributes && (
              <div className="grid grid-cols-2 gap-4 mt-4">
                {Object.entries(data.attributes).map(([key, value]) => (
                  <div key={key} className="border-b border-black pb-2">
                    <p className="text-sm text-black">{key}</p>
                    <p className="text-sm font-medium text-black">{value}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderPlace = (data) => {
    if (!data) return null;

    return (
      <div className="flex flex-col h-full bg-transparent border border-black rounded-lg overflow-hidden">
        <div className="p-4">
          <div className="flex items-start justify-between">
            <div className="flex items-start">
              <MapPin className="w-5 h-5 text-black mt-1" />
              <div className="ml-2">
                <h2 className="text-lg font-semibold text-black">
                  {data.title}
                </h2>
                <p className="text-sm text-black mt-1">{data.address}</p>
              </div>
            </div>
            <button
              onClick={() => onGetDirections && onGetDirections(data)}
              className="flex items-center text-black hover:text-gray-600 transition-colors"
              aria-label="Get directions"
            >
              <Navigation className="w-5 h-5" />
              <span className="ml-1 text-sm">Directions</span>
            </button>
          </div>
        </div>
        <div className="flex justify-end px-4 pb-4">
          {onShare && (
            <button
              onClick={() => onShare(data)}
              className="p-1 rounded-full text-black"
              aria-label="Share location"
            >
              <Share2 className="w-5 h-5" />
            </button>
          )}
          {onBookmark && (
            <button
              onClick={() => onBookmark(data)}
              className={`p-1 rounded-full ml-2 ${
                isBookmarked ? 'text-black' : 'text-black'
              }`}
              aria-label={
                isBookmarked ? 'Remove from bookmarks' : 'Add to bookmarks'
              }
            >
              <Bookmark className="w-5 h-5" />
            </button>
          )}
        </div>
      </div>
    );
  };

  const renderPeopleAlsoAsk = (data) => {
    if (!data) return null;

    return (
      <div className="flex flex-col h-full bg-transparent border border-black rounded-lg overflow-hidden">
        <div className="p-4">
          <h3 className="font-medium text-lg text-black mb-2">{data.question}</h3>
          <p className="text-black mb-2">{data.snippet}</p>
          <a
            href={data.link}
            className="text-black hover:text-gray-600 text-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.title}
          </a>
        </div>
      </div>
    );
  };

  const renderRelatedSearch = (data) => {
    if (!data) return null;

    return (
      <div className="inline-block">
        <button
          onClick={() => onRelatedSearch && onRelatedSearch(data)}
          className="px-4 py-2 bg-transparent hover:bg-gray-100 border border-black rounded-full text-black text-sm"
        >
          {data.query}
        </button>
      </div>
    );
  };

  const renderContent = () => {
    switch (type) {
      case 'knowledge':
        return renderKnowledgeGraph(data);
      case 'place':
        return renderPlace(data);
      case 'peopleAlsoAsk':
        return renderPeopleAlsoAsk(data);
      case 'relatedSearch':
        return renderRelatedSearch(data);
      default:
        return null;
    }
  };

  return renderContent();
};

export { CardSkeleton };
export default Card;
