// SearchField.js
import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setLoading,
  setError,
  clearSearchResults,
  clearDynamicPodcasts,
  setDynamicPodcasts,
  setArticles,
  setIsFeedlyContent
} from '../redux/store';
import { generateDynamicPodcast } from '../utils/dynamicPodcastUtil';

const SearchField = ({ handleSearch }) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const handleInput = () => {
      if (inputRef.current) {
        inputRef.current.style.width = "auto";
        inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
      }
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('input', handleInput);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('input', handleInput);
      }
    };
  }, []);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      const searchTerm = inputRef.current.value.trim();
      if (searchTerm) {
        dispatch(setLoading(true));
        dispatch(clearSearchResults());
        dispatch(clearDynamicPodcasts());
        dispatch(setIsFeedlyContent(false));
        navigate('/results');

        try {
          const result = await generateDynamicPodcast(searchTerm);
          if (result && result.dynamicPodcasts && result.articles) {
            dispatch(setDynamicPodcasts(result.dynamicPodcasts));
            dispatch(setArticles(result.articles));
          } else {
            throw new Error('Invalid response format from API');
          }
        } catch (error) {
          dispatch(setError('Failed to search for news: ' + error.message));
        } finally {
          dispatch(setLoading(false));
        }
      }
    }
  };

  return (
    <input
      ref={inputRef}
      type="text"
      placeholder="Search..."
      className="px-3 py-1 border border-black rounded-md outline-none transition-all duration-300"
      style={{ width: '150px' }}
      onKeyDown={handleKeyPress}
    />
  );
};

export default SearchField;
