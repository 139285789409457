import React, { useState } from 'react';
import FeedlyLogo from '../assets/feedly-logo.svg';

const FeedlyLogoutButton = ({ onLogout }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onClick={onLogout}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`flex items-center transition-all duration-300 ${
        isHovered 
          ? 'px-4 py-1 border border-black rounded-md' 
          : 'px-1 py-1 border-0'
      }`}
    >
      <img 
        src={FeedlyLogo} 
        alt="Feedly" 
        className="w-6 h-6 object-contain"
      />
      <span 
        className={`ml-2 font-semibold text-sm whitespace-nowrap overflow-hidden transition-all duration-300 ${
          isHovered ? 'max-w-[200px] opacity-100' : 'max-w-0 opacity-0'
        }`}
      >
        Logout Feedly
      </span>
    </button>
  );
};

export default FeedlyLogoutButton;
