import axios from 'axios';

const BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend-dev.podsi.xyz';

export const generateDynamicPodcast = async (query) => {
  try {
    console.log('Sending request to:', `${BASE_URL}api/v1/podcasts/search/dynamic-news`);
    console.log('Query:', query);

    const response = await axios.get(`${BASE_URL}api/v1/podcasts/search/dynamic-news`, {
      params: { q: query }
    });

    console.log('Response data:', response.data);

    const { dynamicPodcasts, news } = response.data.data || {};

    if (!dynamicPodcasts || !news) {
      throw new Error('Invalid response format: dynamicPodcasts or news is missing');
    }

    // Ensure news is an array before mapping
    const mappedNews = Array.isArray(news) ? news.map(item => ({
      id: item.link,
      title: item.title,
      source: item.source,
      description: item.description || item.snippet,
      link: item.link,
      date: item.date,
      imageUrl: item.imageUrl
    })) : [];

    return {
      dynamicPodcasts,
      articles: mappedNews
    };
  } catch (error) {
    console.error('Error generating dynamic podcast:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }
    throw error;
  }
};

export default {
  generateDynamicPodcast
};