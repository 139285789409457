import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBookmark, removeBookmark, selectIsBookmarked } from '../../../redux/store';

export const useBookmarks = (items, type) => {
  const dispatch = useDispatch();

  const bookmarks = useSelector(state => {
    return (items || []).reduce((acc, item) => ({
      ...acc,
      [item.id]: selectIsBookmarked(type)(state, item.id)
    }), {});
  });

  const handleBookmark = useCallback((item) => {
    const isBookmarked = bookmarks[item.id];
    if (isBookmarked) {
      dispatch(removeBookmark(item.id, type));
    } else {
      dispatch(addBookmark(item, type));
    }
  }, [dispatch, bookmarks, type]);

  return {
    bookmarks,
    handleBookmark
  };
};