import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFeedlyCategories } from '../utils/feedlyUtil';
import {
  setFeedlyUser,
  setCategories,
  setLoading,
  setError,
  selectFeedlyUser
} from '../redux/store';

function FeedlyLogin({ onLoginClick, onLoginSuccess }) {
  const dispatch = useDispatch();
  const feedlyUser = useSelector(selectFeedlyUser);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const authEndpoint = 'https://cloud.feedly.com/v3/auth/auth';
  const clientId = 'audioone';
  const redirectUri = 'https://feedlyauth.herokuapp.com/feedlyCallback';
  const scope = 'https://cloud.feedly.com/subscriptions';

  const feedlyUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${encodeURIComponent(
    redirectUri
  )}&scope=${encodeURIComponent(scope)}&response_type=code`;

  useEffect(() => {
    window.addEventListener('message', receiveMessage);
    return () => window.removeEventListener('message', receiveMessage);
  }, []);

  useEffect(() => {
    const storedToken = window.localStorage.getItem('feedly_token');
    const storedUserId = window.localStorage.getItem('feedly_id');
    if (storedToken && storedUserId && !feedlyUser.token) {
      dispatch(setFeedlyUser({ token: storedToken, user_id: storedUserId }));
      setLoginSuccess(true);
      onLoginSuccess?.();
    }
  }, [dispatch, feedlyUser.token, onLoginSuccess]);

  useEffect(() => {
    if (feedlyUser.token) {
      console.log('Token available, fetching categories...');
      fetchCategories();
    }
  }, [feedlyUser.token]);

  const receiveMessage = useCallback((e) => {
    console.log('Received message:', e.data);
    if (e.data.type === 'feedlyAuth') {
      console.log('Feedly auth successful');
      const { accessToken, refreshToken, userId } = e.data;
      window.localStorage.setItem('feedly_token', accessToken);
      window.localStorage.setItem('feedly_refresh', refreshToken);
      window.localStorage.setItem('feedly_id', userId);
      dispatch(setFeedlyUser({ token: accessToken, user_id: userId }));
      setLoginSuccess(true);
      onLoginSuccess?.();
    }
  }, [dispatch, onLoginSuccess]);

  const feedlyLogIn = useCallback((e) => {
    if (e) e.preventDefault();
    console.log('Opening Feedly login window');
    window.open(feedlyUrl, 'Login with Feedly', 'width=550,height=600');
  }, [feedlyUrl]);

  useEffect(() => {
    if (onLoginClick) {
      console.log("Passing login function to parent");
      onLoginClick(feedlyLogIn);
    }
  }, [onLoginClick, feedlyLogIn]);

  const feedlyLogOut = () => {
    window.localStorage.removeItem('feedly_token');
    window.localStorage.removeItem('feedly_refresh');
    window.localStorage.removeItem('feedly_id');
    dispatch(setFeedlyUser({ token: null, user_id: null }));
    dispatch({ type: 'CLEAR_FEEDLY_DATA' });
    setLoginSuccess(false);
    console.log('Logged out successfully');
  };

  const fetchCategories = async () => {
    dispatch(setLoading(true));
    try {
      console.log('Fetching categories from Feedly API...');
      const token = window.localStorage.getItem('feedly_token');
      if (!token) {
        throw new Error('Token not found. Please log in again.');
      }
      const categories = await getFeedlyCategories(token);
      dispatch(setCategories(categories));
      console.log('Categories fetched successfully');
    } catch (error) {
      console.error('Error fetching categories:', error);
      dispatch(setError(error.message));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      {/* {!feedlyUser.token ? (
        <button 
          onClick={feedlyLogIn}
          className="px-4 py-2 border border-black rounded-md font-semibold text-sm bg-transparent"
        >
          Login with Feedly
        </button>
      ) : (
        <button 
          onClick={feedlyLogOut}
          className="px-4 py-2 border border-black rounded-md font-semibold text-sm bg-transparent"
        >
          Logout
        </button>
      )}
      {loginSuccess && <p>Login Successful!</p>}
      {feedlyUser.token && (
        <>
          <p>User ID: {feedlyUser.user_id}</p>
          <button 
            onClick={fetchCategories}
            className="px-4 py-2 border border-black rounded-md font-semibold text-sm bg-transparent mt-2"
          >
            Refresh Categories
          </button>
        </>
      )} */}
    </div>
  );
}

export default FeedlyLogin;