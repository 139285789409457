// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { SlideUpProvider } from './providers/SlideUpManager';
import Home from './components/Home'
import Results from './components/results';
import AudioPlayer from './components/AudioPlayer';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <SlideUpProvider>
          <div className="App">
            <AudioPlayer />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/results" element={<Results />} />
            </Routes>
          </div>
        </SlideUpProvider>
      </Router>
    </Provider>
  );
}

export default App;