import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../cards';
import { selectPlaces } from '../../../redux/store';

const PlacesSection = ({ onGetDirections, onShare, onBookmark }) => {
  const places = useSelector(selectPlaces);
  
  if (!places?.length) return null;

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4">Local Results</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {places.map((place) => (
          <Card
            key={place.cid}
            type="place"
            data={place}
            onGetDirections={onGetDirections}
            onShare={() => onShare(place)}
            onBookmark={() => onBookmark(place, 'places')}
          />
        ))}
      </div>
    </div>
  );
};

export default PlacesSection;