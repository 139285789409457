import React from 'react';

const ErrorToast = ({ message }) => {
  // Only show on localhost:3001
  if (window.location.origin !== 'http://localhost:3001') return null;

  return (
    <div
      className="fixed bottom-20 right-2 border border-black-500 text-white px-2 py-1 rounded-md shadow-sm"
      style={{ 
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        fontSize: '12px',
        maxWidth: '200px',
        lineHeight: '1.2'
      }}
    >
      {message}
    </div>
  );
};

export default ErrorToast;
