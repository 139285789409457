import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  setArticles, 
  setError,
  setKnowledgeGraph,
  setOrganicResults,
  setPeopleAlsoAsk,
  setPlaces,
  setRelatedSearches,
  setTopStories,
  clearSearchResults,
  clearDynamicPodcasts,
  setIsFeedlyContent,
  setLoading,
  setSearchType,
  setSearchParameters,
  clearPdfFile,
  selectPdfFile
} from '../../../redux/store';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend-dev.podsi.xyz';

const MAX_RETRIES = 3;

export const useSearch = (isFeedlyContent, feedlyToken, categoryLabel) => {
  const dispatch = useDispatch();
  const queryInitiatedRef = useRef(null);
  const pdfFile = useSelector(selectPdfFile);

  const [loadingArticles, setLoadingArticles] = useState(false);
  const [articlesLoaded, setArticlesLoaded] = useState(false);
  const [podcastGenerationIds, setPodcastGenerationIds] = useState({});
  const [retryCount, setRetryCount] = useState(0);
  const [lastQuery, setLastQuery] = useState(null);

  useEffect(() => {
    setLoadingArticles(false);
    setArticlesLoaded(false);
    setPodcastGenerationIds({});
    setRetryCount(0);
    setLastQuery(null);
    queryInitiatedRef.current = null;
  }, [isFeedlyContent]);

  const fetchArticlesAndInitiatePodcasts = useCallback(async (query) => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchType = searchParams.get('searchType') || 'news';

    dispatch(setSearchType(searchType));

    const queryKey = isFeedlyContent
      ? `${categoryLabel}-feedly`
      : searchType === 'pdf' && pdfFile
        ? `${pdfFile.name}-pdf`
        : `${query}-${searchType}-search`;

    if (queryInitiatedRef.current === queryKey || retryCount >= MAX_RETRIES) {
      return;
    }

    try {
      setLoadingArticles(true);
      dispatch(setLoading(true));
      queryInitiatedRef.current = queryKey;

      let endpoint;
      let requestConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      // Handle special cases first
      if (isFeedlyContent) {
        endpoint = `${API_BASE_URL}/api/v1/feedly/generateAndRespondFeedly`;
        requestConfig.headers['Authorization'] = `Bearer ${feedlyToken}`;
        
        const urlParams = new URLSearchParams();
        urlParams.append('q', query || '');
        urlParams.append('count', '10');
        if (categoryLabel) urlParams.append('category', categoryLabel);
        endpoint = `${endpoint}?${urlParams.toString()}`;
      } else if (searchType === 'url') {
        endpoint = `${API_BASE_URL}/api/v1/url/initiate`;
        requestConfig = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ url: query })
        };
      } else if (searchType === 'pdf') {
        endpoint = `${API_BASE_URL}/api/v1/podcast/initiate`;
        if (pdfFile) {
          dispatch(clearSearchResults());
          dispatch(clearDynamicPodcasts());
          
          const formData = new FormData();
          formData.append('pdf', pdfFile);
          requestConfig = {
            method: 'POST',
            body: formData
          };
        } else {
          throw new Error('No PDF file found');
        }
      } else {
        // For news, organic, and local searches, validate first
        const validateResponse = await fetch(`${API_BASE_URL}/api/v1/validate-dynamic?q=${encodeURIComponent(query)}`);
        if (!validateResponse.ok) {
          throw new Error('Search validation failed');
        }
        const validationData = await validateResponse.json();

        // Determine validated search term based on search type
        let validatedQuery;
        if (searchType === 'news') {
          validatedQuery = validationData.data.google_news_search_term;
        } else {
          validatedQuery = validationData.data.is_google_search.search_term;
        }

        // Set endpoint based on search type with validated query
        switch (searchType) {
          case 'organic':
            endpoint = `${API_BASE_URL}/api/v1/regular/dynamic/initiate`;
            const organicParams = new URLSearchParams();
            organicParams.append('q', validatedQuery);
            organicParams.append('count', '10');
            endpoint = `${endpoint}?${organicParams.toString()}`;
            break;
          case 'local':
            endpoint = `${API_BASE_URL}/api/v1/regular/local/initiate`;
            const localParams = new URLSearchParams();
            localParams.append('q', validatedQuery);
            localParams.append('count', '10');
            localParams.append('location', searchParams.get('location'));
            endpoint = `${endpoint}?${localParams.toString()}`;
            break;
          default:
            endpoint = `${API_BASE_URL}/api/v1/podcasts/search/dynamic-news/initiate`;
            const defaultParams = new URLSearchParams();
            defaultParams.append('q', validatedQuery);
            defaultParams.append('count', '10');
            endpoint = `${endpoint}?${defaultParams.toString()}`;
        }
      }

      const response = await fetch(endpoint, requestConfig);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }

      const data = await response.json();
      if (!data.success && !data.articles) {
        throw new Error(data.error || 'Failed to load content');
      }

      console.log('API Response:', {
        searchType,
        dataKeys: Object.keys(data),
        data
      });

      if (data.results?.searchParameters) {
        dispatch(setSearchParameters(data.results.searchParameters));
      }

      if (searchType === 'organic' && data.results) {
        if (data.results.organic) {
          dispatch(setOrganicResults(data.results.organic));
          dispatch(setArticles(data.results.organic));
        }

        if (data.results.topStories) {
          dispatch(setTopStories(data.results.topStories));
        }

        if (data.results.knowledgeGraph) {
          dispatch(setKnowledgeGraph(data.results.knowledgeGraph));
        }

        if (data.results.places) {
          dispatch(setPlaces(data.results.places));
        }

        if (data.results.peopleAlsoAsk) {
          dispatch(setPeopleAlsoAsk(data.results.peopleAlsoAsk));
        }

        if (data.results.relatedSearches) {
          dispatch(setRelatedSearches(data.results.relatedSearches));
        }
      } else {
        const articles = data.articles || data.news || [];
        dispatch(setArticles(articles));
        
        if (data.topStories) {
          dispatch(setTopStories(data.topStories));
        }

        if (data.knowledgeGraph) {
          dispatch(setKnowledgeGraph(data.knowledgeGraph));
        }

        if (data.places && searchType === 'local') {
          dispatch(setPlaces(data.places));
        }

        if (data.peopleAlsoAsk) {
          dispatch(setPeopleAlsoAsk(data.peopleAlsoAsk));
        }

        if (data.relatedSearches) {
          dispatch(setRelatedSearches(data.relatedSearches));
        }
      }

      if (data.podcasts) {
        setPodcastGenerationIds(data.podcasts);
      } else if (data.generationId) {
        setPodcastGenerationIds({
          topNewsPodcast: data.generationId,
          trendingNewsPodcast: data.generationId,
          topicOverviewPodcast: data.generationId,
          conversationalPodcast: data.generationId
        });
      }

      setArticlesLoaded(true);
      setRetryCount(0);
      setLastQuery(searchType === 'pdf' ? pdfFile.name : query);

      if (searchType === 'pdf') {
        dispatch(clearPdfFile());
      }
    } catch (error) {
      console.error('Error fetching content:', error);
      queryInitiatedRef.current = null;

      if (error.message.includes('401')) {
        dispatch(setError('Authentication failed. Please try logging in again.'));
      } else if (error.message.includes('429')) {
        dispatch(setError('Too many requests. Please try again later.'));
      } else {
        dispatch(setError(error.message || 'Failed to load content'));
        setRetryCount(prev => prev + 1);
      }
    } finally {
      setLoadingArticles(false);
      dispatch(setLoading(false));
    }
  }, [dispatch, isFeedlyContent, categoryLabel, feedlyToken, retryCount, pdfFile]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search).get('q');
    const searchType = new URLSearchParams(window.location.search).get('searchType');
    
    if (searchType === 'pdf' && isFeedlyContent) {
      dispatch(clearSearchResults());
      dispatch(clearDynamicPodcasts());
      dispatch(setIsFeedlyContent(false));
    }
    
    if (isFeedlyContent && categoryLabel) {
      setLastQuery(categoryLabel);
      fetchArticlesAndInitiatePodcasts(query);
      return;
    }

    if (searchType === 'pdf' && pdfFile) {
      fetchArticlesAndInitiatePodcasts(null);
      return;
    }

    if (!isFeedlyContent && query && query !== lastQuery) {
      setLastQuery(query);
      fetchArticlesAndInitiatePodcasts(query);
    }
  }, [fetchArticlesAndInitiatePodcasts, isFeedlyContent, categoryLabel, lastQuery, pdfFile]);

  const retryFetch = useCallback(() => {
    const query = new URLSearchParams(window.location.search).get('q');
    queryInitiatedRef.current = null;
    setRetryCount(0);
    setArticlesLoaded(false);
    setPodcastGenerationIds({});
    fetchArticlesAndInitiatePodcasts(query);
  }, [fetchArticlesAndInitiatePodcasts]);

  return {
    loadingArticles,
    articlesLoaded,
    podcastGenerationIds,
    isRetrying: retryCount > 0,
    retryCount,
    retryFetch,
    hasError: retryCount >= MAX_RETRIES
  };
};

export default useSearch;