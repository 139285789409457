// src/slideups/components/FeedlySlideUp.js

import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSlideUp, SLIDEUP_IDS, SLIDEUP_TYPES } from '../../providers/SlideUpManager';
import { 
  selectCategories,
  selectSelectedCategory,
  setSelectedCategory,
  setLoading,
  setError,
  clearSearchResults,
  clearDynamicPodcasts,
  setIsFeedlyContent,
} from '../../redux/store';
import feedlyLogoWhite from '../../assets/feedly-logo-white.svg';

const FeedlySlideUp = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categories = useSelector(selectCategories);
  const feedlyUser = useSelector((state) => state.news.feedlyUser);
  const selectedCategory = useSelector(selectSelectedCategory);
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { getSlideUpStyle } = useSlideUp();
  const slideUpStyle = getSlideUpStyle(SLIDEUP_IDS.FEEDLY);

  // Filter categories based on search term
  const filteredCategories = useMemo(() => {
    return categories.filter(category => 
      category.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [categories, searchTerm]);

  const handleCategoryClick = (categoryLabel) => {
    if (feedlyUser?.token) {
      dispatch(setSelectedCategory(categoryLabel));
      dispatch(setLoading(true));
      dispatch(setError(null));
      dispatch(clearSearchResults());
      dispatch(clearDynamicPodcasts());
      dispatch(setIsFeedlyContent(true));

      // Check if on mobile and minimize the slideup
      if (window.innerWidth <= 768) {
        setIsMinimized(true);
      }

      // Navigate to results view, where API calls will be handled
      navigate('/results');
    }
  };

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // Calculate height based on screen size
  const getHeight = () => {
    const isMobile = window.innerWidth <= 768;
    return {
      expanded: isMobile ? '575px' : '449px',
      minimized: '67px'
    };
  };

  return (
    <div
      className={`fixed md:w-[297px] w-full ${isVisible ? 'block' : 'hidden'}`}
      style={{
        ...slideUpStyle,
        height: isMinimized ? getHeight().minimized : getHeight().expanded,
        filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))',
        zIndex: 999,
        backgroundColor: 'rgb(25, 25, 25)',
        bottom: isVisible ? '0' : `-${getHeight().expanded}`,
        borderRadius: window.innerWidth > 768 ? '10px' : '10px 10px 0 0',
        transition: 'height 0.3s ease-in-out'
      }}
    >
      {/* Header Section with Gradient */}
      <div
        className="w-full"
        style={{
          position: 'relative',
          height: '67px',
          background: 'linear-gradient(rgb(25, 25, 25) 0%, rgb(25, 25, 25) 18.2%, rgba(25, 25, 25, 0.86) 68.5%, rgba(25, 25, 25, 0) 100%)',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '16px',
          paddingTop: '8px',
          zIndex: '9',
        }}
      >
        {/* Feedly Logo and Text */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={feedlyLogoWhite}
            alt="Feedly Logo"
            style={{
              height: '24px',
              width: 'auto',
              marginRight: '8px',
            }}
          />
          <span style={{
            color: '#FFFF',
            fontWeight: 'bold',
            fontSize: '18px',
            fontFamily: 'Inter, sans-serif',
          }}>
            Feedly
          </span>
        </div>
        {/* Control Buttons */}
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {/* Minimize Button */}
          <div
            style={{
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              color: '#FFFF',
            }}
            onClick={toggleMinimize}
          >
            <span style={{ 
              fontSize: '24px',
              transform: isMinimized ? 'rotate(180deg)' : 'rotate(0deg)',
              display: 'block',
              transition: 'transform 0.3s ease'
            }}>
              ⌃
            </span>
          </div>
          {/* Close Button */}
          <div
            style={{
              width: '28px',
              height: '28px',
              borderRadius: '50%',
              border: '2px solid #FFFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: 'transparent',
            }}
            onClick={onClose}
          >
            <span style={{ 
              fontSize: '20px',
              color: '#FFFF', 
              lineHeight: '1',
              fontWeight: '500',
              marginTop: '-2px'
            }}>×</span>
          </div>
        </div>
      </div>

      {/* Content Area - Only shown when not minimized */}
      {!isMinimized && (
        <>
          <div
            className="w-full"
            style={{
              position: 'absolute',
              top: '48px',
              bottom: '65px',
              overflowY: 'auto',
              padding: '16px',
            }}
          >
            {filteredCategories.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {filteredCategories.map((category) => {
                  const isSelected = selectedCategory === category.label;
                  return (
                    <button
                      key={category.id}
                      style={{
                        padding: '6px 10px',
                        border: '1px solid #FFFF',
                        color: isSelected ? '#1E4073' : '#FFFF',
                        fontSize: '12.5px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        backgroundColor: isSelected ? '#FFFF' : 'transparent',
                        transition: 'background 0.2s, color 0.2s',
                        width: 'auto',
                        whiteSpace: 'nowrap',
                        fontWeight: isSelected ? 'bold' : 'normal',
                      }}
                      onClick={() => handleCategoryClick(category.label)}
                      onMouseEnter={(e) => {
                        if (!isSelected) {
                          e.target.style.background = '#FFFF';
                          e.target.style.color = '#1E4073';
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (!isSelected) {
                          e.target.style.background = 'transparent';
                          e.target.style.color = '#FFFF';
                        }
                      }}
                    >
                      {category.label}
                    </button>
                  );
                })}
              </div>
            ) : (
              <p style={{ color: '#FFFF' }}>No matching categories found</p>
            )}
          </div>

          {/* Footer with Matching Gradient */}
          <div
            className="w-full absolute bottom-0"
            style={{
              height: '97px',
              background: 'linear-gradient(rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.86) 31.5%, rgb(25, 25, 25) 81.8%, rgb(25, 25, 25) 100%)',
              borderBottomLeftRadius: window.innerWidth > 768 ? '16px' : '0',
              borderBottomRightRadius: window.innerWidth > 768 ? '16px' : '0',
              display: 'flex',
              alignItems: 'end',
              paddingBottom: '14px',
              justifyContent: 'center',
            }}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Feedly Categories"
              style={{
                width: '90%',
                maxWidth: '270px',
                height: '40px',
                border: '1px solid rgba(255, 255, 255, 0.5)',
                borderRadius: '12px',
                padding: '0 12px',
                boxSizing: 'border-box',
                color: '#FFFF',
                fontFamily: 'Inter, sans-serif',
                fontSize: '13px',
                backgroundColor: 'transparent',
                outline: 'none',
              }}
              onFocus={(e) => {
                e.target.style.border = '1px solid #FFFF';
              }}
              onBlur={(e) => {
                e.target.style.border = '1px solid rgba(255, 255, 255, 0.5)';
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FeedlySlideUp;