import React from 'react';
import { useSelector } from 'react-redux';
import { MapPin, Link as LinkIcon } from 'lucide-react';
import { selectKnowledgeGraph } from '../../../redux/store';

const KnowledgeGraphSection = () => {
  const knowledgeGraph = useSelector(selectKnowledgeGraph);
  
  if (!knowledgeGraph) return null;

  return (
    <div className="mb-8">
      <div className="flex items-center space-x-4 mb-4">
        {knowledgeGraph.imageUrl && (
          <img
            src={knowledgeGraph.imageUrl}
            alt={knowledgeGraph.title}
            className="h-8 w-auto object-contain mix-blend-multiply"
            style={{ height: '2rem' }} // Matches the title font size for alignment
          />
        )}
        <h2 className="text-2xl font-bold text-black">Knowledge Graph</h2>
      </div>
      <div className="rounded-lg border border-black p-4">
        <div className="flex justify-between items-start">
          <div>
            <h3 className="text-xl font-bold text-black">
              {knowledgeGraph.title}
            </h3>
            <p className="text-sm text-black mt-1">
              {knowledgeGraph.type}
            </p>
          </div>
          {knowledgeGraph.location && (
            <div className="flex items-center text-sm text-black">
              <MapPin className="w-4 h-4 mr-1" />
              <span>{knowledgeGraph.location}</span>
            </div>
          )}
        </div>

        {knowledgeGraph.website && (
          <a
            href={knowledgeGraph.website}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-black hover:opacity-70 text-sm mt-2"
          >
            <LinkIcon className="w-4 h-4 mr-1" />
            <span className="truncate">Visit Website</span>
          </a>
        )}

        {knowledgeGraph.description && (
          <div className="mt-3">
            <p className="text-black text-sm">
              {knowledgeGraph.description}
              {knowledgeGraph.descriptionSource && (
                <a
                  href={knowledgeGraph.descriptionLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1 text-black hover:opacity-70"
                >
                  - {knowledgeGraph.descriptionSource}
                </a>
              )}
            </p>
          </div>
        )}

        {knowledgeGraph.attributes && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            {Object.entries(knowledgeGraph.attributes).map(([key, value]) => (
              <div key={key} className="border-b border-black pb-2">
                <p className="text-sm text-black">{key}</p>
                <p className="text-sm font-medium text-black">{value}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default KnowledgeGraphSection;
