import { useCallback } from 'react';

export const useSortedContent = () => {
  const getSortedContent = useCallback((content, sortOrder) => {
    return [...content].sort((a, b) => {
      const dateA = new Date(a.published || a.date || '');
      const dateB = new Date(b.published || b.date || '');
      
      switch (sortOrder) {
        case 'newest':
          return dateB - dateA;
        case 'oldest':
          return dateA - dateB;
        case 'relevance':
          return 0;
        default:
          return 0;
      }
    });
  }, []);

  return getSortedContent;
};