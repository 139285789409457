import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, List } from 'lucide-react';
import { useSearch } from './hooks/useSearch';
import { ArticleCard, ArticleCardSkeleton } from '../cards';
import Header from '../Header';
import ArticleSection from './ArticleSection';
import PodcastSection from './PodcastSection';
import KnowledgeGraphSection from './sections/KnowledgeGraphSection';
import TopStoriesSection from './sections/TopStoriesSection';
import PlacesSection from './sections/PlacesSection';
import PeopleAlsoAskSection from './sections/PeopleAlsoAskSection';
import RelatedSearchesSection from './sections/RelatedSearchesSection';
import FeedlySlideUp from '../slideups/FeedlySlideUp';
import ErrorToast from '../common/ErrorToast';
import { 
  selectError, 
  selectIsFeedlyContent, 
  selectFeedlyUser,
  selectCategoryLabel,
  selectArticles,
  selectFeedlyArticles,
  selectViewMode,
  selectSortOrder,
  selectSearchType,
  selectKnowledgeGraph,
  selectTopStories,
  selectPlaces,
  selectPeopleAlsoAsk,
  selectRelatedSearches,
  selectUrlContent,
  selectUrlMetadata,
  setUrlContent,
  setUrlMetadata,
  clearFeedlyData,
  setViewMode,
  setSortOrder
} from '../../redux/store';

const ViewModeToggle = ({ viewMode, onViewModeChange }) => (
  <div className="flex items-center space-x-2 border-black rounded-md">
    <button
      onClick={() => onViewModeChange('grid')}
      className={`p-2 ${viewMode === 'grid' ? 'text-black' : 'text-black'}`}
      title="Grid View"
    >
      <Grid size={18} />
    </button>
    <button
      onClick={() => onViewModeChange('list')}
      className={`p-2 ${viewMode === 'list' ? 'bg-blue-50 text-blue-600' : 'text-gray-600'}`}
      title="List View"
    >
      <List size={18} />
    </button>
  </div>
);

const SortOrderSelect = ({ sortOrder, onSortOrderChange }) => (
  <select
    value={sortOrder}
    onChange={(e) => onSortOrderChange(e.target.value)}
    className="px-3 py-1 border border-black rounded-md text-sm text-black bg-transparent focus:outline-none focus:border-black"
  >
    <option value="newest">Newest First</option>
    <option value="oldest">Oldest First</option>
    <option value="relevance">Most Relevant</option>
  </select>
);

const Results = () => {
  const dispatch = useDispatch();

  // Redux selectors
  const error = useSelector(selectError);
  const isFeedlyContent = useSelector(selectIsFeedlyContent);
  const feedlyUser = useSelector(selectFeedlyUser);
  const categoryLabel = useSelector(selectCategoryLabel);
  const articles = useSelector(selectArticles) || [];
  const feedlyArticles = useSelector(selectFeedlyArticles) || [];
  const viewMode = useSelector(selectViewMode);
  const sortOrder = useSelector(selectSortOrder);
  const searchType = useSelector(selectSearchType);
  const knowledgeGraph = useSelector(selectKnowledgeGraph);
  const topStories = useSelector(selectTopStories);
  const places = useSelector(selectPlaces);
  const peopleAlsoAsk = useSelector(selectPeopleAlsoAsk);
  const relatedSearches = useSelector(selectRelatedSearches);
  const urlContent = useSelector(selectUrlContent);
  const urlMetadata = useSelector(selectUrlMetadata);
  
  // Local state
  const [isSlideUpVisible, setSlideUpVisible] = useState(isFeedlyContent);

  // Custom hooks
  const { 
    loadingArticles, 
    articlesLoaded,
    podcastGenerationIds
  } = useSearch(
    isFeedlyContent, 
    feedlyUser.token, 
    categoryLabel
  );

  const handleViewModeChange = React.useCallback((mode) => {
    dispatch(setViewMode(mode));
  }, [dispatch]);

  const handleSortOrderChange = React.useCallback((order) => {
    dispatch(setSortOrder(order));
  }, [dispatch]);

  const handleRelatedSearch = (search) => {
    if (search.query) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('q', search.query);
      searchParams.set('searchType', searchType);
      window.location.search = searchParams.toString();
    }
  };

  return (
    <div className="flex flex-col min-h-screen" style={{backgroundColor: '#b5b5b5'}}>
<Header
  onLogout={() => {
    localStorage.removeItem('feedly_token');
    localStorage.removeItem('feedly_refresh');
    localStorage.removeItem('feedly_id');
    dispatch(clearFeedlyData());
  }}
  isFeedlyLoggedIn={!!feedlyUser.token}
  showLogo={true}
/>
    
      <main className="flex-grow p-4 overflow-y-auto">
        <div className="mx-4 md:max-w-[72%] lg:max-w-[75%] md:ml-16 md:mr-4 md:pl-0">
          {/* Podcast Section */}
          <PodcastSection 
            articlesLoaded={articlesLoaded}
            podcastGenerationIds={podcastGenerationIds}
          />

          {/* Related Searches Section */}
          {searchType === 'organic' && relatedSearches && relatedSearches.length > 0 && (
            <RelatedSearchesSection onRelatedSearch={handleRelatedSearch} />
          )}
          
          {/* Top Stories Section */}
          {topStories && topStories.length > 0 && (
            <TopStoriesSection isLoading={loadingArticles} />
          )}

          {/* Articles Section */}
          <div className="mb-8">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold">
                {isFeedlyContent ? 'Feedly Related Articles' : searchType === 'organic' ? 'Web Results' : 'Related Articles'}
              </h2>
              <div className="hidden md:flex space-x-4">
                <ViewModeToggle 
                  viewMode={viewMode} 
                  onViewModeChange={handleViewModeChange} 
                />
                <SortOrderSelect 
                  sortOrder={sortOrder} 
                  onSortOrderChange={handleSortOrderChange} 
                />
              </div>
            </div>

            <ArticleSection 
              isLoading={loadingArticles}
              viewMode={viewMode}
            />
          </div>

          {/* Places Section */}
          {searchType === 'organic' && places && places.length > 0 && (
            <PlacesSection 
              onGetDirections={(place) => {
                window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(place.title + ' ' + place.address)}`, '_blank');
              }}
              onShare={(place) => {
                const text = `${place.title}\n${place.address}`;
                if (navigator.share) {
                  navigator.share({ text });
                } else {
                  navigator.clipboard.writeText(text);
                }
              }}
            />
          )}

          {/* People Also Ask Section */}
          {searchType === 'organic' && peopleAlsoAsk && peopleAlsoAsk.length > 0 && (
            <PeopleAlsoAskSection />
          )}

          {/* Knowledge Graph Section */}
          {searchType === 'organic' && knowledgeGraph && (
            <KnowledgeGraphSection />
          )}


        </div>
      </main>

      {isFeedlyContent && (
        <FeedlySlideUp
          isVisible={isSlideUpVisible}
          onClose={() => setSlideUpVisible(false)}
        />
      )}

      {error && <ErrorToast message={error} />}
    </div>
  );
};

ViewModeToggle.propTypes = {
  viewMode: PropTypes.string.isRequired,
  onViewModeChange: PropTypes.func.isRequired
};

SortOrderSelect.propTypes = {
  sortOrder: PropTypes.string.isRequired,
  onSortOrderChange: PropTypes.func.isRequired
};

export default Results;