// ArticleCard.js
import React, { useState, useEffect } from 'react';
import { Play, Pause, Share, Bookmark } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  setPlayingState,
  setAudioUrl,
  selectIsPlaying,
  selectCurrentPodcast,
  selectContentType,
  setError,
  resetAudioState,
  setAudioDuration
} from '../../redux/store';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend-dev.podsi.xyz';

const ArticleCard = ({
  article,
  onBookmark,
  onShare,
  isBookmarked = false
}) => {
  const dispatch = useDispatch();
  const [faviconUrl, setFaviconUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isPlaying = useSelector(selectIsPlaying);
  const currentPodcast = useSelector(selectCurrentPodcast);
  const contentType = useSelector(selectContentType);
  const isCurrentArticle = currentPodcast?.id === article.id && contentType === 'article';

  useEffect(() => {
    const fetchFavicon = async () => {
      if (!article.link) return;
      
      try {
        const domain = new URL(article.link).hostname;
        const response = await fetch(`${API_BASE_URL}/api/v1/get-favicon?domain=${domain}`);
        
        if (response.ok) {
          const blob = await response.blob();
          setFaviconUrl(URL.createObjectURL(blob));
        } else {
          setFaviconUrl(null);
        }
      } catch (error) {
        console.error('Error fetching favicon:', error);
        setFaviconUrl(null);
      }
    };

    fetchFavicon();
  }, [article.link]);

  const handlePlay = async (e) => {
    e.stopPropagation(); // Prevent card click event
    if (isCurrentArticle) {
      dispatch(setPlayingState(!isPlaying));
      return;
    }

    try {
      setIsLoading(true);
      const articleData = {
        title: article.title,
        link: article.link,
        description: article.description || article.content || article.snippet,
        source: article.source
      };

      const response = await fetch(`${API_BASE_URL}/api/v1/playArticles`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          article: articleData,
          options: {
            language: "en-US",
            quality: "high",
            format: "mp3"
          }
        })
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch audio: ${response.status}`);
      }

      const responseData = await response.json();
      
      if (!responseData.data?.url) {
        throw new Error('No audio URL received in response');
      }

      dispatch(resetAudioState());
      
      const tempAudio = new Audio();
      tempAudio.src = responseData.data.url;
      
      await new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          reject(new Error('Audio loading timeout'));
        }, 10000);

        tempAudio.addEventListener('loadedmetadata', () => {
          clearTimeout(timeoutId);
          dispatch(setAudioDuration(tempAudio.duration));
          dispatch(setAudioUrl(responseData.data.url, {
            ...article,
            id: article.id || uuidv4(),
            type: 'article'
          }));
          dispatch(setPlayingState(true));
          resolve();
        });

        tempAudio.addEventListener('error', (e) => {
          clearTimeout(timeoutId);
          reject(new Error(`Audio loading failed: ${e.message}`));
        });

        tempAudio.load();
      });
    } catch (error) {
      console.error('Error playing article:', error);
      dispatch(setError(`Failed to play article: ${error.message}`));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardClick = () => {
    if (article.link) {
      window.open(article.link, '_blank', 'noopener,noreferrer');
    }
  };

  const handleBookmarkClick = (e) => {
    e.stopPropagation(); // Prevent card click event
    onBookmark?.(article);
  };

  const handleShareClick = (e) => {
    e.stopPropagation(); // Prevent card click event
    onShare?.(article);
  };

  return (
    <div 
      className="rounded-lg p-3 flex flex-col h-full border border-black hover:bg-[#d8d5d5] transition-colors cursor-pointer"
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleCardClick();
        }
      }}
    >
      <div className="flex items-start space-x-3 mb-2">
        {article.imageUrl && (
          <img 
            src={article.imageUrl} 
            alt={article.title} 
            className="w-16 h-16 object-cover rounded" 
          />
        )}
        <div className={`flex-1 ${!article.imageUrl ? 'ml-0' : ''}`}>
          <div className="flex items-center space-x-2 mb-1">
            {faviconUrl && (
              <img 
                src={faviconUrl} 
                alt={`${article.source} favicon`}
                className="w-4 h-4 object-contain"
                onError={() => setFaviconUrl(null)}
              />
            )}
            <p className="text-xs text-gray-600">
              {article.source}
            </p>
          </div>
          <h3 className="font-bold text-sm leading-tight">
            {article.title}
          </h3>
        </div>
      </div>
      <div className="flex-grow" />
      <div className="flex items-center justify-between w-full mt-2">
        <p className="text-xs text-gray-500">
          {article.date || article.published}
        </p>
        <div className="flex items-center space-x-2">
          <button 
            onClick={handlePlay} 
            disabled={isLoading}
            className="hover:opacity-80 transition-opacity disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label={isCurrentArticle && isPlaying ? "Pause" : "Play"}
          >
            {isLoading ? (
              <div className="w-4 h-4 border-2 border-black border-t-transparent rounded-full animate-spin" />
            ) : isCurrentArticle && isPlaying ? (
              <Pause size={16} />
            ) : (
              <Play size={16} />
            )}
          </button>
          <button 
            onClick={handleBookmarkClick}
            className={`hover:opacity-80 transition-opacity ${isBookmarked ? 'text-blue-600' : ''}`}
            aria-label={isBookmarked ? "Remove bookmark" : "Add bookmark"}
          >
            <Bookmark size={16} />
          </button>
          <button 
            onClick={handleShareClick}
            className="hover:opacity-80 transition-opacity"
            aria-label="Share article"
          >
            <Share size={16} />
          </button>
        </div>
      </div>
    </div>
  );
};

const ArticleCardSkeleton = () => (
  <div className="rounded-lg p-3 flex flex-col h-full border border-black animate-pulse">
    <div className="flex items-start space-x-3 mb-2">
      <div className="w-16 h-16 bg-gray-300 rounded" />
      <div className="flex-1">
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-2" />
        <div className="h-4 bg-gray-300 rounded" />
      </div>
    </div>
    <div className="flex-grow" />
    <div className="flex items-center justify-between w-full mt-2">
      <div className="h-4 bg-gray-300 rounded w-1/4" />
      <div className="flex items-center space-x-2">
        <div className="w-4 h-4 bg-gray-300 rounded-full" />
        <div className="w-4 h-4 bg-gray-300 rounded-full" />
        <div className="w-4 h-4 bg-gray-300 rounded-full" />
        <div className="w-16 h-6 bg-gray-300 rounded" />
      </div>
    </div>
  </div>
);

export default ArticleCard;
export { ArticleCardSkeleton };
