import React, { useEffect, useState, useRef } from 'react';
import { Play, Pause, Share, Bookmark, FileText, Volume2 } from 'lucide-react';

const getRandomDarkColor = () => {
  const colors = [
    '#1E40AF', '#047857', '#7C3AED', '#BE185D', '#B91C1C',
    '#0E7490', '#6D28D9', '#7C2D12', '#3730A3', '#831843'
  ];
  return colors[Math.floor(Math.random() * colors.length)];
};

const PodcastCard = ({
  podcast,
  isPlaying = false,
  isLoading = false,
  loadingStatus,
  onPlay,
  onPause,
  onBookmark,
  onShare,
  isBookmarked = false,
  onHover,
  loadingText = 'Loading...',
  articleCount
}) => {
  const [backgroundColor] = useState(() => getRandomDarkColor());
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [hasEverBeenPlayed, setHasEverBeenPlayed] = useState(false);
  const previousLoadingRef = useRef(isLoading);
  const previousStatusRef = useRef(loadingStatus);
  
  const searchType = new URLSearchParams(window.location.search).get('searchType');
  const isPdf = searchType === 'pdf';
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (!previousLoadingRef.current && isLoading) {
      // New podcast is being selected, reset states
      setOverlayVisible(false);
      setHasEverBeenPlayed(false);
    }
    
    if (previousLoadingRef.current && !isLoading && 
        previousStatusRef.current === 'playing' && 
        loadingStatus === 'playing' &&
        !hasEverBeenPlayed) {
      // Show overlay only if this podcast has never been played
      setOverlayVisible(true);
    }

    previousLoadingRef.current = isLoading;
    previousStatusRef.current = loadingStatus;
  }, [isLoading, loadingStatus, hasEverBeenPlayed]);

  // Track when the podcast starts playing for the first time
  useEffect(() => {
    if (isPlaying && !hasEverBeenPlayed) {
      setHasEverBeenPlayed(true);
    }
  }, [isPlaying]);

  const handlePlayPause = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (isPlaying) {
      onPause?.();
    } else {
      setOverlayVisible(false);
      onPlay?.(podcast);
    }
  };

  const formatPodcastType = (type) => {
    if (!type) return 'Unknown Type';
    return type
      .replace(/([A-Z])/g, ' $1')
      .trim()
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getPlayButtonContent = () => {
    if (isLoading) {
      switch (loadingStatus) {
        case 'generating':
          return <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />;
        case 'playing':
          return <Volume2 size={24} color="white" className="animate-pulse" />;
        default:
          return <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />;
      }
    }
    return isPlaying ? <Pause size={24} color="white" /> : <Play size={24} color="white" />;
  };

  const getLoadingStatusText = () => {
    if (isLoading) {
      switch (loadingStatus) {
        case 'generating':
          return 'Preparing preview...';
        case 'playing':
          return 'Playing preview...';
        default:
          return loadingText || 'Loading...';
      }
    }
    return null;
  };

  // Only show overlay if it's visible AND the podcast has never been played
  const shouldShowOverlay = isMobile && overlayVisible && !hasEverBeenPlayed && !isPlaying;

  return (
    <div 
      className="flex-shrink-0 w-[300px]"
      onMouseEnter={() => onHover?.(podcast.title)}
    >
      <div className="w-full h-[340px] rounded-lg overflow-hidden relative">
        {/* Background/Image */}
        {podcast.imageUrl ? (
          <>
            <img 
              src={podcast.imageUrl} 
              alt={podcast.title} 
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-black opacity-70" />
          </>
        ) : (
          <div 
            className="w-full h-full flex items-end p-4"
            style={{ backgroundColor }}
          />
        )}
        
        {/* Mobile Ready Overlay */}
        {shouldShowOverlay && (
          <div className="absolute inset-0 bg-black/80 z-10 flex flex-col justify-center p-6">
            <div className="mb-6">
              <h3 className="text-white text-xl font-bold mb-2 text-left">
                Your Featured Podcast Is Ready
              </h3>
              <p className="text-white/90 text-sm text-left">
                {podcast.title}
              </p>
            </div>
            <div className="flex items-start">
              <button 
                onClick={handlePlayPause}
                className="w-16 h-16 rounded-full bg-white/20 hover:bg-white/30 flex items-center justify-center transition-all duration-200"
                aria-label="Play"
              >
                <Play size={32} color="white" />
              </button>
            </div>
          </div>
        )}
        
        {/* Content Overlay */}
        <div className="absolute top-4 left-4 right-4">
          {/* Tags/Badges */}
          <div className="flex flex-wrap items-center gap-2 mb-2">
            {isPdf && podcast.type === 'conversationalPodcast' && (
              <div className="flex items-center gap-1 px-2 py-0.5 bg-blue-600 text-white text-xs font-medium rounded-full">
                <FileText size={12} />
                <span>PDF</span>
              </div>
            )}
            <span className="px-2 py-0.5 bg-black/50 text-white text-xs font-medium rounded-full">
              {formatPodcastType(podcast.type)}
            </span>
            {articleCount !== undefined && (
              <span className="px-2 py-0.5 bg-black/50 text-white text-xs font-medium rounded-full">
                {articleCount} {articleCount === 1 ? 'Article' : 'Articles'}
              </span>
            )}
          </div>

          {/* Title */}
          <h3 className="font-bold text-lg leading-tight text-white mb-2">
            {podcast.title}
          </h3>

          {/* Loading Status */}
          {getLoadingStatusText() && (
            <div className="mt-2 text-sm text-white/90 flex items-center gap-2">
              <span className="animate-pulse">{getLoadingStatusText()}</span>
            </div>
          )}
        </div>

        {/* Play Button - Only show if overlay is not visible */}
        {(!shouldShowOverlay) && (
          <button 
            onClick={handlePlayPause}
            disabled={isLoading && loadingStatus === 'generating'}
            className={`absolute bottom-4 left-4 p-2 rounded-full transition-all duration-200 ease-in-out
              ${isLoading && loadingStatus === 'generating' 
                ? 'bg-gray-500 cursor-not-allowed' 
                : 'bg-black bg-opacity-50 hover:bg-opacity-70 cursor-pointer'}`}
            aria-label={isPlaying ? 'Pause' : 'Play'}
          >
            {getPlayButtonContent()}
          </button>
        )}

        {/* Action Buttons */}
        <div className="absolute bottom-4 right-4 flex items-center space-x-2">
          <button 
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onBookmark?.(podcast);
            }}
            className={`p-1 hover:opacity-80 transition-opacity
            ${isBookmarked ? 'text-blue-400' : 'text-white'}`}
            aria-label={isBookmarked ? 'Remove Bookmark' : 'Add Bookmark'}
          >
            <Bookmark size={20} />
          </button>
          <button 
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onShare?.(podcast);
            }}
            className="p-1 text-white hover:opacity-80 transition-opacity"
            aria-label="Share"
          >
            <Share size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

const PodcastCardSkeleton = ({ error = false, isPdf = false, loadingText = 'Loading...' }) => (
  <div className="flex-shrink-0 w-[300px]">
    <div className={`w-full h-[340px] rounded-lg overflow-hidden relative ${
      error ? 'bg-red-100' : 'bg-gray-200'
    } animate-pulse`}>
      {error ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <p className="text-red-500 text-sm px-4 text-center">
            Failed to generate podcast
          </p>
        </div>
      ) : (
        <div className="absolute top-4 left-4 right-4">
          {isPdf && (
            <div className="mb-2">
              <div className="h-6 w-16 bg-gray-300 rounded-full"></div>
            </div>
          )}
          <div className="space-y-2">
            <div className="h-4 bg-gray-300 rounded w-3/4"></div>
            <div className="h-4 bg-gray-300 rounded w-1/2"></div>
            {loadingText && (
              <div className="mt-4 text-sm text-gray-500 animate-pulse">
                {loadingText}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  </div>
);

export { PodcastCardSkeleton };
export default PodcastCard;