// RelatedSearchesSection.js
import React from 'react';
import { useSelector } from 'react-redux';
import { selectRelatedSearches } from '../../../redux/store';

const RelatedSearchesSection = ({ onRelatedSearch }) => {
  const relatedSearches = useSelector(selectRelatedSearches);

  if (!relatedSearches?.length) return null;

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4">Related Searches</h2>
      <div className="flex flex-wrap gap-2">
        {relatedSearches.map((search, index) => (
          <button
            key={index}
            onClick={() => onRelatedSearch(search)}
            className="bg-transparent shadow-none border border-black rounded-lg px-3 py-1 cursor-pointer hover:bg-gray-300 transition-all duration-300 ease-in-out"
          >
            {search.query || "Untitled"}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RelatedSearchesSection;
