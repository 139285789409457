import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { useLoadingAudio } from '../../results/hooks/useLoadingAudio';
import { 
  addDynamicPodcast, 
  setError,
  resetAudioState,
  setAudioDuration,
  setAudioUrl,
  setPlayingState,
  selectIsPlaying,
  selectCurrentPodcast
} from '../../../redux/store';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : 'https://backend-dev.podsi.xyz';

const MAX_POLL_ATTEMPTS = 10;
const POLL_INTERVAL = 5000; // 5 seconds

export const usePodcastGeneration = (
  articlesLoaded,
  isFeedlyContent,
  feedlyToken,
  podcastGenerationIds
) => {
  const dispatch = useDispatch();
  const mounted = useRef(true);
  const activePolls = useRef(new Map());
  const initialPollStarted = useRef(false);

  // Audio state
  const isPlaying = useSelector(selectIsPlaying);
  const currentPodcast = useSelector(selectCurrentPodcast);

  // Loading audio hooks
  const {
    playLoadingAudio,
    stopLoadingAudio,
    setMainPodcastReady,
    status: loadingAudioStatus,
  } = useLoadingAudio();

  // State
  const [podcastGenerationStatus, setPodcastGenerationStatus] = useState({});
  const [loadingPodcastId, setLoadingPodcastId] = useState(null);
  const pendingMainPodcastRef = useRef(null);

  // Helper function to format articles for TTS request
  const formatArticlesForTTS = useCallback((articles) => {
    return articles.map(article => ({
      title: article.title,
      published: article.published || article.date || new Date().toISOString(),
      source: article.source || "Unknown Source",
      link: article.link || article.url,
      _id: article.id || uuidv4(),
      content: article.content || article.snippet,
      imageUrl: article.imageUrl || article.image
    }));
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
      activePolls.current.forEach(timeout => clearTimeout(timeout));
      activePolls.current.clear();
      initialPollStarted.current = false;
      stopLoadingAudio();
      pendingMainPodcastRef.current = null;
    };
  }, [stopLoadingAudio]);

  // Initialize podcast generation status when IDs are received
  useEffect(() => {
    if (podcastGenerationIds && Object.keys(podcastGenerationIds).length > 0) {
      // Clear existing polls
      activePolls.current.forEach(timeout => clearTimeout(timeout));
      activePolls.current.clear();
      
      // Reset poll started flag
      initialPollStarted.current = false;
      
      console.log('Initializing podcast generation status:', podcastGenerationIds);
      const initialStatus = Object.entries(podcastGenerationIds).reduce((acc, [type, id]) => ({
        ...acc,
        [type]: {
          generationId: id,
          loading: true,
          error: false,
          retryCount: 0
        }
      }), {});
      setPodcastGenerationStatus(initialStatus);
    }
  }, [podcastGenerationIds]);

  // Determine endpoint based on search type
  const getPodcastCheckEndpoint = useCallback(() => {
    const searchType = new URLSearchParams(window.location.search).get('searchType');
    
    if (searchType === 'pdf') {
      return `${API_BASE_URL}/api/v1/podcast/check-ready`;
    }
    if (searchType === 'url') {
      return `${API_BASE_URL}/api/v1/url/check-ready`;
    }
    if (isFeedlyContent) {
      return `${API_BASE_URL}/api/v1/feedly/checkPodcastsReadyFeedly`;
    }
    if (searchType === 'local') {
      return `${API_BASE_URL}/api/v1/regular/dynamic/check-ready`;
    }
    if (searchType === 'organic') {
      return `${API_BASE_URL}/api/v1/regular/dynamic/check-ready`;
    }
    return `${API_BASE_URL}/api/v1/podcasts/search/dynamic-news/check-ready`; // Default to 'news'
  }, [isFeedlyContent]);

  // Polling function for podcast generation status
  const pollPodcastStatus = useCallback(async (podcastType, status) => {
    if (!mounted.current || status.retryCount >= MAX_POLL_ATTEMPTS) {
      activePolls.current.delete(podcastType);
      if (status.retryCount >= MAX_POLL_ATTEMPTS) {
        console.log(`Max polling attempts reached for ${podcastType}`);
        if (mounted.current) {
          dispatch(setError(`Podcast generation timed out for ${podcastType}`));
          setPodcastGenerationStatus(prev => ({
            ...prev,
            [podcastType]: { ...prev[podcastType], loading: false, error: true }
          }));
          stopLoadingAudio();
        }
      }
      return;
    }

    try {
      const endpoint = getPodcastCheckEndpoint();
      console.log(`Checking podcast status: ${endpoint}?generationId=${status.generationId}`);

      const headers = {
        'Content-Type': 'application/json',
        ...(isFeedlyContent && { Authorization: `Bearer ${feedlyToken}` })
      };

      const response = await fetch(`${endpoint}?generationId=${status.generationId}`, { headers });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      if (!mounted.current) return;

      if (responseData.success) {
        console.log(`Podcast ready for ${podcastType}:`, responseData);
        const podcastData = responseData.dynamicPodcasts[podcastType];
        
        dispatch(addDynamicPodcast({
          type: podcastType,
          data: podcastData
        }));
        
        setPodcastGenerationStatus(prev => ({
          ...prev,
          [podcastType]: { ...prev[podcastType], loading: false, error: false }
        }));
        
        activePolls.current.delete(podcastType);

        // If this is the loading podcast, notify loading audio hook
        if (loadingPodcastId === podcastData.id) {
          console.log('Podcast generation complete, notifying loading audio hook');
          setMainPodcastReady(podcastData.audioUrl, podcastData);
        }
      } else {
        // Schedule next poll
        const timeoutId = setTimeout(() => {
          setPodcastGenerationStatus(prev => ({
            ...prev,
            [podcastType]: {
              ...prev[podcastType],
              retryCount: prev[podcastType].retryCount + 1
            }
          }));
          pollPodcastStatus(podcastType, {
            ...status,
            retryCount: status.retryCount + 1
          });
        }, POLL_INTERVAL);
        activePolls.current.set(podcastType, timeoutId);
      }
    } catch (error) {
      console.error(`Error checking podcast status for ${podcastType}:`, error);
      
      if (!mounted.current) return;

      if (error.message.includes('401')) {
        dispatch(setError('Authentication failed. Please try logging in again.'));
        activePolls.current.delete(podcastType);
        stopLoadingAudio();
      } else if (error.message.includes('429')) {
        dispatch(setError('Too many requests. Please try again later.'));
        activePolls.current.delete(podcastType);
        stopLoadingAudio();
      } else {
        // Schedule retry for other errors
        const timeoutId = setTimeout(() => {
          setPodcastGenerationStatus(prev => ({
            ...prev,
            [podcastType]: {
              ...prev[podcastType],
              retryCount: prev[podcastType].retryCount + 1
            }
          }));
          pollPodcastStatus(podcastType, {
            ...status,
            retryCount: status.retryCount + 1
          });
        }, POLL_INTERVAL);
        activePolls.current.set(podcastType, timeoutId);
      }
    }
  }, [dispatch, getPodcastCheckEndpoint, isFeedlyContent, feedlyToken, loadingPodcastId, stopLoadingAudio, setMainPodcastReady]);

  // Start polling when status changes
  useEffect(() => {
    if (!articlesLoaded || Object.keys(podcastGenerationStatus).length === 0 || initialPollStarted.current) {
      return;
    }

    initialPollStarted.current = true;
    console.log('Starting initial podcast polling');

    Object.entries(podcastGenerationStatus).forEach(([podcastType, status]) => {
      if (status.loading && !activePolls.current.has(podcastType)) {
        console.log(`Starting polling for ${podcastType}`);
        pollPodcastStatus(podcastType, status);
      }
    });
  }, [articlesLoaded, podcastGenerationStatus, pollPodcastStatus]);

  // Generate TTS content
  const generatePodcastTTS = useCallback(async (podcast, articles) => {
    try {
      const searchType = new URLSearchParams(window.location.search).get('searchType');
      let ttsEndpoint;

      if (searchType === 'pdf') {
        ttsEndpoint = `${API_BASE_URL}/api/v1/podcast/tts`;
      } else {
        ttsEndpoint = `${API_BASE_URL}/api/v1/podcasts/search/dynamic-news/tts`;
      }

      // Encode textUrl to avoid URI validation errors
      const encodedTextUrl = encodeURI(podcast.textUrl || '');

      // Build request body based on podcast type and search type
      let requestBody;
      if (searchType === 'pdf' && podcast.type === 'conversationalPodcast') {
        requestBody = {
          textUrl: encodedTextUrl,
          showNotes: podcast.show_notes,
          ttsStyle: 'conversational',
          intro: { enabled: false },
          outro: { enabled: false },
          backgroundMusic: { enabled: false }
        };
      } else {
        requestBody = {
          news: formatArticlesForTTS(articles),
          ttsStyle: podcast.type === 'conversationalPodcast' ? 'conversational' : 'solo',
          intro: { enabled: false },
          outro: { enabled: false },
          backgroundMusic: { enabled: false }
        };
      }

      const response = await fetch(ttsEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(isFeedlyContent && { Authorization: `Bearer ${feedlyToken}` })
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      // Handle both response formats
      if (searchType === 'pdf') {
        // Handle double-nested response for PDF
        if (data.ttsUrl?.success && data.ttsUrl?.data) {
          return data.ttsUrl.data;  // Return the actual URL
        }
        return data.ttsUrl;  // For PDF conversational case
      } else {
        if (data.status === 'success' && data.data) {
          return data.data;
        }
        throw new Error('Invalid response format from TTS service');
      }
    } catch (error) {
      console.error('TTS generation error:', error);
      throw error;
    }
  }, [isFeedlyContent, feedlyToken, formatArticlesForTTS]);

  // Handle podcast playback
  const handlePodcastPlay = useCallback(async (podcast, getFilteredArticles) => {
    if (!mounted.current) return;
    
    // If this is the same podcast, just toggle play state
    if (currentPodcast?.id === podcast.id) {
      dispatch(setPlayingState(!isPlaying));
      return;
    }
    
    try {
      setLoadingPodcastId(podcast.id);
      pendingMainPodcastRef.current = podcast;
      
      // Get articles if needed
      const searchType = new URLSearchParams(window.location.search).get('searchType');
      const isPdfConversational = searchType === 'pdf' && podcast.type === 'conversationalPodcast';
      
      let articles = [];
      if (!isPdfConversational) {
        articles = getFilteredArticles(podcast);
        if (!articles.length && searchType !== 'pdf') {
          throw new Error('No articles found for this podcast');
        }
      }

      // Start loading audio while podcast generates
      await playLoadingAudio(podcast);

      // Generate podcast in background
      const audioUrl = await generatePodcastTTS(podcast, articles);
      
      if (!mounted.current) return;

      console.log('Main podcast generated, signaling ready');
      setMainPodcastReady(audioUrl, podcast);

    } catch (error) {
      console.error('Error playing podcast:', error);
      if (mounted.current) {
        dispatch(setError(error.message || 'Failed to play podcast'));
        dispatch(setPlayingState(false));
        stopLoadingAudio();
      }
    } finally {
      if (mounted.current) {
        setLoadingPodcastId(null);
      }
    }
  }, [
    dispatch,
    generatePodcastTTS,
    currentPodcast,
    isPlaying,
    mounted,
    playLoadingAudio,
    stopLoadingAudio,
    setMainPodcastReady
  ]);

  const handlePodcastPause = useCallback(() => {
    dispatch(setPlayingState(false));
  }, [dispatch]);

  return {
    podcastGenerationStatus,
    loadingPodcastId,
    setLoadingPodcastId,
    loadingAudioStatus,
    handlePodcastPlay,
    handlePodcastPause
  };
};

export default usePodcastGeneration;