import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Play, Pause, Volume2 } from 'lucide-react';
import BackwardIcon from '../assets/images/backward10.svg';
import ForwardIcon from '../assets/images/forward10.svg';
import {
  setPlayingState,
  setAudioTime,
  setAudioDuration,
  selectIsPlaying,
  selectCurrentPodcast,
  selectAudioUrl,
  selectCurrentTime,
  selectDuration,
  selectIsPreview
} from '../redux/store';

const AudioPlayer = () => {
  const dispatch = useDispatch();
  const audioRef = useRef(new Audio());
  const seekSliderRef = useRef(null);
  const titleRef = useRef(null);
  const previousUrlRef = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isMobileExpanded, setIsMobileExpanded] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);
  const isSeekingRef = useRef(false);
  const animationFrameRef = useRef(null);

  // Get audio state from Redux
  const isPlaying = useSelector(selectIsPlaying);
  const currentPodcast = useSelector(selectCurrentPodcast);
  const audioUrl = useSelector(selectAudioUrl);
  const globalCurrentTime = useSelector(selectCurrentTime);
  const globalDuration = useSelector(selectDuration);
  const isPreview = useSelector(selectIsPreview);

  // Initialize local state with Redux values
  const [currentTime, setLocalCurrentTime] = useState(globalCurrentTime || 0);
  const [duration, setLocalDuration] = useState(globalDuration || 0);

  // Add new state for preview playing
  const [isPreviewPlaying, setIsPreviewPlaying] = useState(false);

  // Update useEffect to check if current audio is a preview
  useEffect(() => {
    if (currentPodcast?.isPreview) {
      setIsPreviewPlaying(true);
    } else {
      setIsPreviewPlaying(false);
    }
  }, [currentPodcast]);

  // Update duration when audio metadata is loaded
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
  
    const handleLoadedMetadata = () => {
      const audioDuration = audio.duration;
      if (!isNaN(audioDuration)) {
        setLocalDuration(audioDuration);
        dispatch(setAudioDuration(audioDuration));
      }
    };
  
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    
    if (audio.duration && !isNaN(audio.duration)) {
      handleLoadedMetadata();
    }
  
    return () => {
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
    };
  }, [dispatch, audioUrl]);

  // Handle time updates using requestAnimationFrame
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const updateTime = () => {
      if (!isSeekingRef.current && audio) {
        setLocalCurrentTime(audio.currentTime);
        dispatch(setAudioTime(audio.currentTime));
      }
      animationFrameRef.current = requestAnimationFrame(updateTime);
    };

    const handleEnded = () => {
      dispatch(setPlayingState(false));
      setLocalCurrentTime(0);
      dispatch(setAudioTime(0));
      cancelAnimationFrame(animationFrameRef.current);
    };

    if (isPlaying) {
      animationFrameRef.current = requestAnimationFrame(updateTime);
    }

    audio.addEventListener('ended', handleEnded);

    return () => {
      cancelAnimationFrame(animationFrameRef.current);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [isPlaying, dispatch]);

  // Handle audio source changes
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio || !audioUrl) return;

    if (audioUrl !== previousUrlRef.current) {
      const wasPlaying = !audio.paused;
      audio.src = audioUrl;
      previousUrlRef.current = audioUrl;
      
      setLocalCurrentTime(0);
      setLocalDuration(0);
      dispatch(setAudioTime(0));
      
      const handleMetadataLoaded = () => {
        setLocalDuration(audio.duration);
        dispatch(setAudioDuration(audio.duration));
        
        if (wasPlaying) {
          audio.play().catch(error => {
            console.error('Playback failed:', error);
            dispatch(setPlayingState(false));
          });
        }
      };

      audio.addEventListener('loadedmetadata', handleMetadataLoaded, { once: true });
      audio.load();
    }
  }, [audioUrl, dispatch]);

  // Handle play/pause state changes
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    if (isPlaying && audio.paused) {
      const playPromise = audio.play();
      if (playPromise !== undefined) {
        playPromise.catch(error => {
          console.error('Playback failed:', error);
          dispatch(setPlayingState(false));
        });
      }
    } else if (!isPlaying && !audio.paused) {
      audio.pause();
    }
  }, [isPlaying, dispatch]);

  // Calculate title height
  useEffect(() => {
    if (titleRef.current) {
      const titleHeight = titleRef.current.scrollHeight;
      setTitleHeight(titleHeight);
    }
  }, [currentPodcast?.title]);

  const playPauseToggle = (e) => {
    e.stopPropagation();
    dispatch(setPlayingState(!isPlaying));
  };

  const handleSeekStart = () => {
    isSeekingRef.current = true;
    cancelAnimationFrame(animationFrameRef.current);
  };
  
  const handleSeekChange = (e) => {
    const newTime = parseFloat(e.target.value);
    setLocalCurrentTime(newTime);
  };
  
  const handleSeekEnd = (e) => {
    const audio = audioRef.current;
    if (!audio) return;
  
    const newTime = parseFloat(e.target.value);
    audio.currentTime = newTime;
    setLocalCurrentTime(newTime);
    dispatch(setAudioTime(newTime));
    isSeekingRef.current = false;

    if (isPlaying) {
      audio.play();
    }
  };

  const seekForward = (e) => {
    e.stopPropagation();
    const audio = audioRef.current;
    if (!audio) return;

    const newTime = Math.min(audio.currentTime + 10, audio.duration);
    audio.currentTime = newTime;
    setLocalCurrentTime(newTime);
    dispatch(setAudioTime(newTime));
  };

  const seekBackward = (e) => {
    e.stopPropagation();
    const audio = audioRef.current;
    if (!audio) return;

    const newTime = Math.max(audio.currentTime - 10, 0);
    audio.currentTime = newTime;
    setLocalCurrentTime(newTime);
    dispatch(setAudioTime(newTime));
  };

  const formatTime = (seconds) => {
    if (!seconds || isNaN(seconds)) return '0:00';
    const minutes = Math.floor(seconds / 60);
    seconds = Math.floor(seconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // Add debug logging effect at the top with other effects
  useEffect(() => {
    console.log('Current podcast state:', {
      isPlaying,
      isPreview: currentPodcast?.isPreview,
      podcast: currentPodcast
    });
  }, [isPlaying, currentPodcast]);

  // Helper functions after all hooks
  const renderPlayPauseButton = () => {
    if (isPlaying) {
      if (isPreview || currentPodcast?.type === 'loading') {
        return (
          <Volume2 
            size={24} 
            className="text-blue-500 animate-pulse" 
          />
        );
      }
      return <Pause size={24} />;
    }
    return <Play size={24} />;
  };

  const handleContainerClick = () => {
    if (window.innerWidth <= 768) {
      setIsMobileExpanded(!isMobileExpanded);
    }
  };

  if (!currentPodcast) return null;

  const baseHeight = Math.max(75, titleHeight + 45);
  const hoveredHeight = baseHeight + 50;

  return (
    <div
      className={`${isHovered || isMobileExpanded ? 'hovered' : ''} transition-all duration-300 ease-in-out`}
      onMouseEnter={() => window.innerWidth > 768 && setIsHovered(true)}
      onMouseLeave={() => window.innerWidth > 768 && setIsHovered(false)}
      onClick={handleContainerClick}
      style={{
        position: 'fixed',
        top: window.innerWidth > 768 ? '96px' : 'auto',
        bottom: window.innerWidth > 768 ? 'auto' : '0',
        right: window.innerWidth > 768 ? '20px' : '0',
        width: window.innerWidth > 768 ? '297px' : '100%',
        height: isHovered || isMobileExpanded ? `${hoveredHeight}px` : `${baseHeight}px`,
        background: '#ffffff',
        borderRadius: window.innerWidth > 768 ? '10px' : '10px 10px 0 0',
        padding: '15px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        zIndex: 40,
        cursor: window.innerWidth <= 768 ? 'pointer' : 'default'
      }}
    >
      <div className="player-wrapper">
        <div className="play-title">
          {currentPodcast.imageUrl ? (
            <img
              src={currentPodcast.imageUrl}
              alt={currentPodcast.title}
              className="play-title-img"
            />
          ) : (
            <div className="play-title-img-placeholder" />
          )}
          <div ref={titleRef} className="play-title-label">
            {currentPodcast?.title || 'Podcast Title'}
          </div>
        </div>
        <div className="play-icons">
          <button onClick={playPauseToggle} className="play-btn">
            {renderPlayPauseButton()}
          </button>
        </div>
      </div>
      <div className={`seeker-wrapper ${isHovered || isMobileExpanded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}>
        <div className="seek-container">
          <input
            ref={seekSliderRef}
            type="range"
            min="0"
            max={duration || 0}
            value={currentTime || 0}
            step="0.01"
            onMouseDown={handleSeekStart}
            onTouchStart={handleSeekStart}
            onChange={handleSeekChange}
            onMouseUp={handleSeekEnd}
            onTouchEnd={handleSeekEnd}
            className="seek-slider"
            onClick={(e) => e.stopPropagation()}
          />
          <div className="timestamps">
            <span>{formatTime(currentTime)}</span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
        <div className="forward-backward-btns">
          <button onClick={seekBackward} className="seek-btn">
            <img src={BackwardIcon} alt="Seek Backward" className="seek-icon" />
          </button>
          <button onClick={seekForward} className="seek-btn">
            <img src={ForwardIcon} alt="Seek Forward" className="seek-icon" />
          </button>
        </div>
      </div>
      <audio ref={audioRef} preload="metadata">
        Your browser does not support the audio element.
      </audio>
      <style jsx>{`
        .player-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 15px;
        }

        .play-title {
          display: flex;
          align-items: flex-start;
          flex: 1;
          min-width: 0;
        }

        .play-title-img {
          margin-right: 12px;
          border-radius: 5px;
          width: 45px;
          height: 45px;
          object-fit: cover;
          flex-shrink: 0;
        }

        .play-title-img-placeholder {
          width: 45px;
          height: 45px;
          background: #e0e0e0;
          border-radius: 5px;
          margin-right: 12px;
          flex-shrink: 0;
        }

        .play-title-label {
          font-size: 14px;
          line-height: 1.3;
          max-width: 170px;
          word-break: break-word;
          overflow-wrap: break-word;
        }

        .play-icons {
          display: flex;
          align-items: center;
          margin-left: 8px;
        }

        .play-btn {
          background: none;
          border: none;
          cursor: pointer;
          padding: 0;
        }

        .seeker-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 15px;
          height: 40px;
        }

        .seek-container {
          width: 65%;
        }

        .seek-slider {
          width: 100%;
          margin-bottom: 5px;
          -webkit-appearance: none;
          background: transparent;
        }

        .seek-slider::-webkit-slider-thumb {
          -webkit-appearance: none;
          height: 14px;
          width: 14px;
          border-radius: 50%;
          background: #000000;
          cursor: pointer;
          margin-top: -5px;
        }

        .seek-slider::-moz-range-thumb {
          height: 14px;
          width: 14px;
          border-radius: 50%;
          background: #000000;
          cursor: pointer;
        }

        .seek-slider::-webkit-slider-runnable-track {
          width: 100%;
          height: 4px;
          cursor: pointer;
          background: #000000;
          border-radius: 2px;
        }

        .seek-slider::-moz-range-track {
          width: 100%;
          height: 4px;
          cursor: pointer;
          background: #000000;
          border-radius: 2px;
        }

        .seek-slider:focus {
          outline: none;
        }

        .timestamps {
          display: flex;
          justify-content: space-between;
          font-size: 11px;
          color: #555;
        }

        .forward-backward-btns {
          display: flex;
          gap: 15px;
        }

        .seek-btn {
          background: none;
          border: none;
          cursor: pointer;
          padding: 0;
        }

        .seek-icon {
          width: 24px;
          height: 24px;
          width: 24px;
          height: 24px;
        }

        @media (max-width: 768px) {
          .play-title-label {
            font-size: 12px;
            max-width: 140px;
          }

          .seek-container {
            width: 100%;
          }

          .forward-backward-btns {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default AudioPlayer;