// src/slideups/components/SearchLocalSlideUp.js

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MapPin } from 'lucide-react';
import { useSlideUp, SLIDEUP_IDS, SLIDEUP_TYPES } from '../../providers/SlideUpManager';

const SearchLocalSlideUp = ({ isVisible, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMinimized, setIsMinimized] = React.useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { getSlideUpStyle } = useSlideUp();
  const slideUpStyle = getSlideUpStyle(SLIDEUP_IDS.LOCAL_SEARCH);

  const toggleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  // Calculate height based on screen size
  const getHeight = () => {
    const isMobile = window.innerWidth <= 768;
    return {
      expanded: isMobile ? '575px' : '449px',
      minimized: '67px'
    };
  };

  return (
    <div
      className={`fixed md:w-[297px] w-full ${isVisible ? 'block' : 'hidden'}`}
      style={{
        ...slideUpStyle,
        height: isMinimized ? getHeight().minimized : getHeight().expanded,
        filter: 'drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161))',
        zIndex: 999,
        backgroundColor: 'rgb(25, 25, 25)',
        bottom: isVisible ? '0' : `-${getHeight().expanded}`,
        borderRadius: window.innerWidth > 768 ? '10px' : '10px 10px 0 0',
        transition: 'height 0.3s ease-in-out'
      }}
    >
      {/* Header Section with Gradient */}
      <div
        className="w-full"
        style={{
          position: 'relative',
          height: '67px',
          background: 'linear-gradient(rgb(25, 25, 25) 0%, rgb(25, 25, 25) 18.2%, rgba(25, 25, 25, 0.86) 68.5%, rgba(25, 25, 25, 0) 100%)',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: '16px',
          paddingTop: '8px',
          zIndex: '9',
        }}
      >
        {/* Local Search Title with Pin Icon */}
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <MapPin className="w-4 h-4" color="#FFFFFF" />
          <span style={{
            color: '#FFFF',
            fontWeight: 'bold',
            fontSize: '18px',
            fontFamily: 'Inter, sans-serif',
          }}>
            Local Search
          </span>
        </div>
        {/* Control Buttons */}
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          {/* Minimize Button */}
          <div
            style={{
              width: '32px',
              height: '32px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              color: '#FFFF',
            }}
            onClick={toggleMinimize}
          >
            <span style={{ 
              fontSize: '24px',
              transform: isMinimized ? 'rotate(180deg)' : 'rotate(0deg)',
              display: 'block',
              transition: 'transform 0.3s ease'
            }}>
              ⌃
            </span>
          </div>
          {/* Close Button */}
          <div
            style={{
              width: '28px',
              height: '28px',
              borderRadius: '50%',
              border: '2px solid #FFFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: 'transparent',
            }}
            onClick={onClose}
          >
            <span style={{ 
              fontSize: '20px',
              color: '#FFFF', 
              lineHeight: '1',
              fontWeight: '500',
              marginTop: '-2px'
            }}>×</span>
          </div>
        </div>
      </div>

      {/* Content Area - Only shown when not minimized */}
      {!isMinimized && (
        <>
          <div
            className="w-full"
            style={{
              position: 'absolute',
              top: '48px',
              bottom: '65px',
              overflowY: 'auto',
              padding: '16px',
            }}
          >
            <div style={{ 
              color: '#FFFF',
              textAlign: 'left',
              padding: '40px 20px'
            }}>
              <h3 style={{ 
                fontSize: '18px',
                marginBottom: '16px',
                fontWeight: 'bold'
              }}>
                Coming Soon!
              </h3>
              <p style={{
                fontSize: '14px',
                lineHeight: '1.5',
                opacity: '0.9'
              }}>
                Local Search will allow you to discover content from your area, including local news, events, and community updates. Stay tuned for this exciting feature!
              </p>
            </div>
          </div>

          {/* Footer with Matching Gradient */}
          <div
            className="w-full absolute bottom-0"
            style={{
              height: '97px',
              background: 'linear-gradient(rgba(25, 25, 25, 0) 0%, rgba(25, 25, 25, 0.86) 31.5%, rgb(25, 25, 25) 81.8%, rgb(25, 25, 25) 100%)',
              borderBottomLeftRadius: window.innerWidth > 768 ? '16px' : '0',
              borderBottomRightRadius: window.innerWidth > 768 ? '16px' : '0',
              display: 'flex',
              alignItems: 'end',
              paddingBottom: '14px',
              justifyContent: 'center',
            }}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search Local Content (Coming Soon)"
              style={{
                width: '90%',
                maxWidth: '270px',
                height: '40px',
                border: '1px solid rgba(255, 255, 255, 0.5)',
                borderRadius: '12px',
                padding: '0 12px',
                boxSizing: 'border-box',
                color: '#FFFF',
                fontFamily: 'Inter, sans-serif',
                fontSize: '13px',
                backgroundColor: 'transparent',
                outline: 'none',
              }}
              onFocus={(e) => {
                e.target.style.border = '1px solid #FFFF';
              }}
              onBlur={(e) => {
                e.target.style.border = '1px solid rgba(255, 255, 255, 0.5)';
              }}
              disabled
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SearchLocalSlideUp;