// SearchButton.js
import React, { useState, useRef, useEffect } from 'react';
import { Search } from 'lucide-react';

const SearchButton = ({ onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <button
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`flex items-center transition-all duration-300 ${
        isHovered
          ? 'px-4 py-1 border border-black rounded-md'
          : 'px-1 py-1 border-0'
      }`}
    >
      <Search className="w-6 h-6 cursor-pointer" />
      <span
        className={`ml-2 font-semibold text-sm whitespace-nowrap overflow-hidden transition-all duration-300 ${
          isHovered ? 'max-w-[200px] opacity-100' : 'max-w-0 opacity-0'
        }`}
      >
        Search
      </span>
    </button>
  );
};

export default SearchButton;