import { createStore } from 'redux';

// Action Types - Add new PDF-related types
export const SET_PDF_FILE = 'SET_PDF_FILE';
export const CLEAR_PDF_FILE = 'CLEAR_PDF_FILE';

// Existing action types
export const SET_FEEDLY_USER = 'SET_FEEDLY_USER';
export const SET_ARTICLES = 'SET_ARTICLES';
export const SET_FEEDLY_ARTICLES = 'SET_FEEDLY_ARTICLES';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const CLEAR_FEEDLY_DATA = 'CLEAR_FEEDLY_DATA';
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS';
export const CLEAR_FEEDLY_ARTICLES = 'CLEAR_FEEDLY_ARTICLES';
export const SET_DYNAMIC_PODCASTS = 'SET_DYNAMIC_PODCASTS';
export const CLEAR_DYNAMIC_PODCASTS = 'CLEAR_DYNAMIC_PODCASTS';
export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_IS_FEEDLY_CONTENT = 'SET_IS_FEEDLY_CONTENT';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_ARTICLES_LOADING = 'SET_ARTICLES_LOADING';
export const SET_PODCASTS_LOADING = 'SET_PODCASTS_LOADING';
export const ADD_DYNAMIC_PODCAST = 'ADD_DYNAMIC_PODCAST';
export const SET_SEARCH_TYPE = 'SET_SEARCH_TYPE';
export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';
export const SET_KNOWLEDGE_GRAPH = 'SET_KNOWLEDGE_GRAPH';
export const SET_ORGANIC_RESULTS = 'SET_ORGANIC_RESULTS';
export const SET_PEOPLE_ALSO_ASK = 'SET_PEOPLE_ALSO_ASK';
export const SET_PLACES = 'SET_PLACES';
export const SET_RELATED_SEARCHES = 'SET_RELATED_SEARCHES';
export const RESET_SEARCH_STATE = 'RESET_SEARCH_STATE';
export const RESET_NEWS_STATE = 'RESET_NEWS_STATE';
export const SET_TOP_STORIES = 'SET_TOP_STORIES';

// Audio Related Action Types
export const SET_PLAYING_STATE = 'SET_PLAYING_STATE';
export const SET_AUDIO_URL = 'SET_AUDIO_URL';
export const SET_AUDIO_TIME = 'SET_AUDIO_TIME';
export const SET_AUDIO_DURATION = 'SET_AUDIO_DURATION';
export const SET_AUDIO_VOLUME = 'SET_AUDIO_VOLUME';
export const SET_AUDIO_MUTED = 'SET_AUDIO_MUTED';
export const SET_PLAYBACK_RATE = 'SET_PLAYBACK_RATE';
export const RESET_AUDIO_STATE = 'RESET_AUDIO_STATE';

// Bookmarks Action Types
export const ADD_BOOKMARK = 'ADD_BOOKMARK';
export const REMOVE_BOOKMARK = 'REMOVE_BOOKMARK';
export const CLEAR_BOOKMARKS = 'CLEAR_BOOKMARKS';

// View Settings Action Types
export const SET_VIEW_MODE = 'SET_VIEW_MODE';
export const SET_SORT_ORDER = 'SET_SORT_ORDER';

// URL Related Action Types
export const SET_URL_CONTENT = 'SET_URL_CONTENT';
export const SET_URL_METADATA = 'SET_URL_METADATA';
export const CLEAR_URL_DATA = 'CLEAR_URL_DATA';

// Load persisted data from localStorage
const loadPersistedData = () => {
  try {
    const token = localStorage.getItem('feedly_token');
    const userId = localStorage.getItem('feedly_id');
    
    const bookmarks = JSON.parse(localStorage.getItem('bookmarks')) || {
      articles: [],
      podcasts: []
    };
    
    const viewSettings = JSON.parse(localStorage.getItem('viewSettings')) || {
      mode: 'grid',
      sortOrder: 'newest'
    };

    const audioSettings = JSON.parse(localStorage.getItem('audioSettings')) || {
      volume: 1,
      playbackRate: 1,
      isMuted: false
    };

    return {
      feedlyUser: { token, user_id: userId },
      bookmarks,
      viewSettings,
      audioSettings
    };
  } catch (error) {
    console.error('Error loading persisted data:', error);
    return null;
  }
};

// Initial state with PDF support added
const createInitialState = () => {
  const persistedData = loadPersistedData();
  
  return {
    search: {
      searchParameters: null,
      searchType: 'news',
      knowledgeGraph: null,
      organicResults: [],
      peopleAlsoAsk: [],
      places: [],
      relatedSearches: [],
      topStories: [],
      isLoading: false,
      error: null,
      pdfFile: null  // New PDF file state
    },
    news: {
      feedlyUser: persistedData?.feedlyUser || { token: null, user_id: null },
      articles: [],
      feedlyArticles: [],
      searchResults: [],
      categories: [],
      dynamicPodcasts: {},
      isLoading: false,
      isArticlesLoading: false,
      isPodcastsLoading: false,
      error: null,
      isFeedlyContent: false,
      selectedCategory: null,
      bookmarks: persistedData?.bookmarks || { articles: [], podcasts: [] },
      viewSettings: persistedData?.viewSettings || { mode: 'grid', sortOrder: 'newest' }
    },
    audio: {
      isPlaying: false,
      currentPodcast: null,
      audioUrl: null,
      currentTime: 0,
      duration: 0,
      volume: persistedData?.audioSettings?.volume || 1,
      isMuted: persistedData?.audioSettings?.isMuted || false,
      playbackRate: persistedData?.audioSettings?.playbackRate || 1,
      isLoaded: false,
      contentType: null
    },
    urlContent: null,
    urlMetadata: null,
  };
};

const initialState = createInitialState();

// Utility function for bookmarks
const updateBookmarks = (bookmarks, item, type) => {
  const existingBookmarks = bookmarks[type];
  const exists = existingBookmarks.find(bookmark => bookmark.id === item.id);
  
  if (exists) {
    return {
      ...bookmarks,
      [type]: existingBookmarks.filter(bookmark => bookmark.id !== item.id)
    };
  }
  
  return {
    ...bookmarks,
    [type]: [...existingBookmarks, item]
  };
};

// Root reducer with PDF support
function rootReducer(state = initialState, action) {
  switch (action.type) {
    // PDF Actions
    case SET_PDF_FILE:
      return {
        ...state,
        search: {
          ...state.search,
          pdfFile: action.payload
        }
      };

    case CLEAR_PDF_FILE:
      return {
        ...state,
        search: {
          ...state.search,
          pdfFile: null
        }
      };

    // Reset States
    case RESET_SEARCH_STATE:
      return {
        ...state,
        search: {
          ...initialState.search,
          searchType: state.search.searchType // Preserve only the search type
        }
      };

    case RESET_NEWS_STATE:
      return {
        ...state,
        news: {
          ...initialState.news,
          feedlyUser: state.news.feedlyUser,
          bookmarks: state.news.bookmarks,
          viewSettings: state.news.viewSettings
        }
      };

    // Search Related Actions
    case SET_SEARCH_TYPE:
      return {
        ...state,
        search: {
          ...state.search,
          searchType: action.payload
        }
      };

    case SET_SEARCH_PARAMETERS:
      return {
        ...state,
        search: {
          ...state.search,
          searchParameters: action.payload
        }
      };

    case SET_KNOWLEDGE_GRAPH:
      return {
        ...state,
        search: {
          ...state.search,
          knowledgeGraph: action.payload
        }
      };

    case SET_ORGANIC_RESULTS:
      return {
        ...state,
        search: {
          ...state.search,
          organicResults: action.payload
        }
      };

    case SET_PEOPLE_ALSO_ASK:
      return {
        ...state,
        search: {
          ...state.search,
          peopleAlsoAsk: action.payload
        }
      };

    case SET_PLACES:
      return {
        ...state,
        search: {
          ...state.search,
          places: action.payload
        }
      };

    case SET_RELATED_SEARCHES:
      return {
        ...state,
        search: {
          ...state.search,
          relatedSearches: action.payload
        }
      };

    case SET_TOP_STORIES:
      return {
        ...state,
        search: {
          ...state.search,
          topStories: action.payload
        }
      };

    // Audio State Management
    case SET_PLAYING_STATE:
      return {
        ...state,
        audio: {
          ...state.audio,
          isPlaying: action.payload
        }
      };

    case SET_AUDIO_URL:
      return {
        ...state,
        audio: {
          ...state.audio,
          audioUrl: action.payload.url,
          currentPodcast: {
            ...action.payload.podcast,
            isPreview: action.payload.podcast?.type === 'loading'
          },
          contentType: action.payload.contentType,
          isLoaded: false
        }
      };

    case SET_AUDIO_TIME:
      return {
        ...state,
        audio: {
          ...state.audio,
          currentTime: action.payload
        }
      };

    case SET_AUDIO_DURATION:
      return {
        ...state,
        audio: {
          ...state.audio,
          duration: action.payload,
          isLoaded: true
        }
      };

    case SET_AUDIO_VOLUME:
      return {
        ...state,
        audio: {
          ...state.audio,
          volume: action.payload
        }
      };

    case SET_AUDIO_MUTED:
      return {
        ...state,
        audio: {
          ...state.audio,
          isMuted: action.payload
        }
      };

    case SET_PLAYBACK_RATE:
      return {
        ...state,
        audio: {
          ...state.audio,
          playbackRate: action.payload
        }
      };

    case RESET_AUDIO_STATE:
      return {
        ...state,
        audio: {
          ...initialState.audio,
          volume: state.audio.volume,
          playbackRate: state.audio.playbackRate
        }
      };

    // News and Content Management
    case SET_FEEDLY_USER:
      return {
        ...state,
        news: {
          ...state.news,
          feedlyUser: action.payload
        }
      };

    case SET_ARTICLES:
      return {
        ...state,
        news: {
          ...state.news,
          articles: action.payload,
          feedlyArticles: []
        }
      };

    case SET_FEEDLY_ARTICLES:
      return {
        ...state,
        news: {
          ...state.news,
          feedlyArticles: action.payload,
          articles: []
        }
      };

    case SET_SEARCH_RESULTS:
      return {
        ...state,
        news: {
          ...state.news,
          searchResults: action.payload
        }
      };

    case SET_CATEGORIES:
      return {
        ...state,
        news: {
          ...state.news,
          categories: action.payload
        }
      };

    case SET_DYNAMIC_PODCASTS:
      return {
        ...state,
        news: {
          ...state.news,
          dynamicPodcasts: action.payload
        }
      };

    case ADD_DYNAMIC_PODCAST:
      return {
        ...state,
        news: {
          ...state.news,
          dynamicPodcasts: {
            ...state.news.dynamicPodcasts,
            [action.payload.type]: action.payload.data
          }
        }
      };

    // Loading and Error States
    case SET_LOADING:
      return {
        ...state,
        news: {
          ...state.news,
          isLoading: action.payload
        }
      };

    case SET_ERROR:
      return {
        ...state,
        news: {
          ...state.news,
          error: action.payload
        }
      };

    case SET_ARTICLES_LOADING:
      return {
        ...state,
        news: {
          ...state.news,
          isArticlesLoading: action.payload
        }
      };

    case SET_PODCASTS_LOADING:
      return {
        ...state,
        news: {
          ...state.news,
          isPodcastsLoading: action.payload
        }
      };

    case SET_IS_FEEDLY_CONTENT:
      return {
        ...state,
        news: {
          ...state.news,
          isFeedlyContent: action.payload
        }
      };

    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        news: {
          ...state.news,
          selectedCategory: action.payload
        }
      };

    // Bookmark Management
    case ADD_BOOKMARK:
      return {
        ...state,
        news: {
          ...state.news,
          bookmarks: updateBookmarks(
            state.news.bookmarks,
            action.payload.item,
            action.payload.type
          )
        }
      };

    case REMOVE_BOOKMARK:
      return {
        ...state,
        news: {
          ...state.news,
          bookmarks: {
            ...state.news.bookmarks,
            [action.payload.type]: state.news.bookmarks[action.payload.type]
              .filter(item => item.id !== action.payload.itemId)
          }
        }
      };

    case CLEAR_BOOKMARKS:
      return {
        ...state,
        news: {
          ...state.news,
          bookmarks: initialState.news.bookmarks
        }
      };

    // View Settings
    case SET_VIEW_MODE:
      return {
        ...state,
        news: {
          ...state.news,
          viewSettings: {
            ...state.news.viewSettings,
            mode: action.payload
          }
        }
      };

    case SET_SORT_ORDER:
      return {
        ...state,
        news: {
          ...state.news,
          viewSettings: {
            ...state.news.viewSettings,
            sortOrder: action.payload
          }
        }
      };

    // Clear Actions
    case CLEAR_FEEDLY_DATA:
      return {
        ...state,
        news: {
          ...state.news,
          feedlyUser: initialState.news.feedlyUser,
          feedlyArticles: [],
          categories: [],
          selectedCategory: null,
          isFeedlyContent: false
        }
      };

      case CLEAR_SEARCH_RESULTS:
        return {
          ...state,
          news: {
            ...state.news,
            searchResults: [],
            articles: []
          },
          search: {
            ...initialState.search,
            searchType: state.search.searchType,
            pdfFile: state.search.pdfFile // Preserve PDF file during clear
          }
        };
  
      case CLEAR_FEEDLY_ARTICLES:
        return {
          ...state,
          news: {
            ...state.news,
            feedlyArticles: []
          }
        };
  
      case CLEAR_DYNAMIC_PODCASTS:
        return {
          ...state,
          news: {
            ...state.news,
            dynamicPodcasts: {}
          }
        };
  
      case SET_URL_CONTENT:
        return {
          ...state,
          urlContent: action.payload
        };
      case SET_URL_METADATA:
        return {
          ...state,
          urlMetadata: action.payload
        };
      case CLEAR_URL_DATA:
        return {
          ...state,
          urlContent: null,
          urlMetadata: null
        };
  
      default:
        return state;
    }
  }
  
  // Create store with Redux DevTools support
  export const store = createStore(
    rootReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  
  // Store subscription for persistence
  store.subscribe(() => {
    const state = store.getState();
    try {
      localStorage.setItem('bookmarks', JSON.stringify(state.news.bookmarks));
      localStorage.setItem('viewSettings', JSON.stringify(state.news.viewSettings));
      localStorage.setItem('audioSettings', JSON.stringify({
        volume: state.audio.volume,
        playbackRate: state.audio.playbackRate,
        isMuted: state.audio.isMuted
      }));
    } catch (error) {
      console.error('Error persisting data:', error);
    }
  });
  
  // PDF Action Creators
  export const setPdfFile = (file) => ({
    type: SET_PDF_FILE,
    payload: file
  });
  
  export const clearPdfFile = () => ({
    type: CLEAR_PDF_FILE
  });
  
  // Existing Action Creators
  export const resetSearchState = () => ({
    type: RESET_SEARCH_STATE
  });
  
  export const resetNewsState = () => ({
    type: RESET_NEWS_STATE
  });
  
  export const setSearchType = (searchType) => ({
    type: SET_SEARCH_TYPE,
    payload: searchType
  });
  
  export const setSearchParameters = (parameters) => ({
    type: SET_SEARCH_PARAMETERS,
    payload: parameters
  });
  
  export const setKnowledgeGraph = (data) => ({
    type: SET_KNOWLEDGE_GRAPH,
    payload: data
  });
  
  export const setOrganicResults = (results) => ({
    type: SET_ORGANIC_RESULTS,
    payload: results
  });
  
  export const setPeopleAlsoAsk = (data) => ({
    type: SET_PEOPLE_ALSO_ASK,
    payload: data
  });
  
  export const setPlaces = (places) => ({
    type: SET_PLACES,
    payload: places
  });
  
  export const setRelatedSearches = (searches) => ({
    type: SET_RELATED_SEARCHES,
    payload: searches
  });
  
  export const setTopStories = (stories) => ({
    type: SET_TOP_STORIES,
    payload: stories
  });
  
  export const setFeedlyUser = (user) => ({
    type: SET_FEEDLY_USER,
    payload: user
  });
  
  export const setArticles = (articles) => ({
    type: SET_ARTICLES,
    payload: articles
  });
  
  export const setFeedlyArticles = (articles) => ({
    type: SET_FEEDLY_ARTICLES,
    payload: articles
  });
  
  export const setSearchResults = (results) => ({
    type: SET_SEARCH_RESULTS,
    payload: results
  });
  
  export const setCategories = (categories) => ({
    type: SET_CATEGORIES,
    payload: categories
  });
  
  export const setDynamicPodcasts = (podcasts) => ({
    type: SET_DYNAMIC_PODCASTS,
    payload: podcasts
  });
  
  export const addDynamicPodcast = (podcast) => ({
    type: ADD_DYNAMIC_PODCAST,
    payload: podcast
  });
  
  export const setLoading = (isLoading) => ({
    type: SET_LOADING,
    payload: isLoading
  });
  
  export const setError = (error) => ({
    type: SET_ERROR,
    payload: error
  });
  
  export const setArticlesLoading = (isLoading) => ({
    type: SET_ARTICLES_LOADING,
    payload: isLoading
  });
  
  export const setPodcastsLoading = (isLoading) => ({
    type: SET_PODCASTS_LOADING,
    payload: isLoading
  });
  
  export const setIsFeedlyContent = (isFeedly) => ({
    type: SET_IS_FEEDLY_CONTENT,
    payload: isFeedly
  });
  
  export const setSelectedCategory = (category) => ({
    type: SET_SELECTED_CATEGORY,
    payload: category
  });
  
  export const addBookmark = (item, type) => ({
    type: ADD_BOOKMARK,
    payload: { item, type }
  });
  
  export const removeBookmark = (itemId, type) => ({
    type: REMOVE_BOOKMARK,
    payload: { itemId, type }
  });
  
  export const clearBookmarks = () => ({
    type: CLEAR_BOOKMARKS
  });
  
  export const setViewMode = (mode) => ({
    type: SET_VIEW_MODE,
    payload: mode
  });
  
  export const setSortOrder = (order) => ({
    type: SET_SORT_ORDER,
    payload: order
  });
  
  export const setPlayingState = (isPlaying) => ({
    type: SET_PLAYING_STATE,
    payload: isPlaying
  });
  
  export const setAudioUrl = (url, podcast) => ({
    type: SET_AUDIO_URL,
    payload: { 
      url, 
      podcast: {
        ...podcast,
        isPreview: podcast?.type === 'loading'
      },
      contentType: podcast?.type || 'podcast'
    }
  });
  
  export const setAudioTime = (time) => ({
    type: SET_AUDIO_TIME,
    payload: time
  });
  
  export const setAudioDuration = (duration) => ({
    type: SET_AUDIO_DURATION,
    payload: duration
  });
  
  export const setAudioVolume = (volume) => ({
    type: SET_AUDIO_VOLUME,
    payload: volume
  });
  
  export const setAudioMuted = (isMuted) => ({
    type: SET_AUDIO_MUTED,
    payload: isMuted
  });
  
  export const setPlaybackRate = (rate) => ({
    type: SET_PLAYBACK_RATE,
    payload: rate
  });
  
  export const resetAudioState = () => ({
    type: RESET_AUDIO_STATE
  });
  
  export const clearFeedlyData = () => ({
    type: CLEAR_FEEDLY_DATA
  });
  
  export const clearSearchResults = () => ({
    type: CLEAR_SEARCH_RESULTS
  });
  
  export const clearFeedlyArticles = () => ({
    type: CLEAR_FEEDLY_ARTICLES
  });
  
  export const clearDynamicPodcasts = () => ({
    type: CLEAR_DYNAMIC_PODCASTS
  });
  
  // URL Related Action Creators
  export const setUrlContent = (content) => ({
    type: SET_URL_CONTENT,
    payload: content
  });
  
  export const setUrlMetadata = (metadata) => ({
    type: SET_URL_METADATA,
    payload: metadata
  });
  
  export const clearUrlData = () => ({
    type: CLEAR_URL_DATA
  });
  
  // Selectors
  export const selectPdfFile = (state) => state.search.pdfFile;
  export const selectSearchType = (state) => state.search.searchType;
  export const selectSearchParameters = (state) => state.search.searchParameters;
  export const selectKnowledgeGraph = (state) => state.search.knowledgeGraph;
  export const selectOrganicResults = (state) => state.search.organicResults;
  export const selectPeopleAlsoAsk = (state) => state.search.peopleAlsoAsk;
  export const selectPlaces = (state) => state.search.places;
  export const selectRelatedSearches = (state) => state.search.relatedSearches;
  export const selectTopStories = (state) => state.search.topStories;
  
  export const selectFeedlyUser = (state) => state.news.feedlyUser;
  export const selectArticles = (state) => state.news.articles;
  export const selectFeedlyArticles = (state) => state.news.feedlyArticles;
  export const selectSearchResults = (state) => state.news.searchResults;
  export const selectCategories = (state) => state.news.categories;
  export const selectDynamicPodcasts = (state) => state.news.dynamicPodcasts;
  export const selectIsLoading = (state) => state.news.isLoading;
  export const selectArticlesLoading = (state) => state.news.isArticlesLoading;
  export const selectPodcastsLoading = (state) => state.news.isPodcastsLoading;
  export const selectError = (state) => state.news.error;
  export const selectIsFeedlyContent = (state) => state.news.isFeedlyContent;
  export const selectSelectedCategory = (state) => state.news.selectedCategory;
  export const selectCategoryLabel = (state) => state.news.selectedCategory;
  
  export const selectBookmarks = (state) => state.news.bookmarks;
  export const selectArticleBookmarks = (state) => state.news.bookmarks.articles;
  export const selectPodcastBookmarks = (state) => state.news.bookmarks.podcasts;
  export const selectIsBookmarked = (type) => (state, id) => 
    state.news.bookmarks[type].some(item => item.id === id);
  
  export const selectViewSettings = (state) => state.news.viewSettings;
  export const selectViewMode = (state) => state.news.viewSettings.mode;
  export const selectSortOrder = (state) => state.news.viewSettings.sortOrder;
  
  export const selectAudioState = (state) => state.audio;
  export const selectIsPlaying = (state) => state.audio.isPlaying;
  export const selectCurrentPodcast = (state) => state.audio.currentPodcast;
  export const selectAudioUrl = (state) => state.audio.audioUrl;
  export const selectCurrentTime = (state) => state.audio.currentTime;
  export const selectDuration = (state) => state.audio.duration;
  export const selectVolume = (state) => state.audio.volume;
  export const selectIsMuted = (state) => state.audio.isMuted;
  export const selectPlaybackRate = (state) => state.audio.playbackRate;
  export const selectIsAudioLoaded = (state) => state.audio.isLoaded;
  export const selectContentType = (state) => state.audio.contentType;
  
  export const selectUrlContent = (state) => state.urlContent;
  export const selectUrlMetadata = (state) => state.urlMetadata;
  
  export const selectIsPreview = (state) => state.audio.currentPodcast?.isPreview || false;
  
  export default store;