import React from 'react';
import { useSelector } from 'react-redux';
import { ArticleCard, ArticleCardSkeleton } from '../../cards';
import { useBookmarks } from '../hooks/useBookmarks';
import { useSortedContent } from '../hooks/useSortedContent';
import {
  selectTopStories,
  selectSortOrder,
} from '../../../redux/store';

const TopStoriesSection = ({ isLoading }) => {
  // Redux selectors
  const topStories = useSelector(selectTopStories) || [];
  const sortOrder = useSelector(selectSortOrder);

  const getSortedContent = useSortedContent();

  // Process stories to match article format
  const processedStories = React.useMemo(() => {
    return topStories.map(story => ({
      id: story.link, // Use link as unique identifier
      title: story.title,
      link: story.link,
      source: story.source,
      published: story.date,
      imageUrl: story.imageUrl,
      description: story.snippet || story.description,
      content: story.snippet || story.description,
      date: story.date,
      snippet: story.snippet || story.description,
      url: story.link
    }));
  }, [topStories]);

  const { bookmarks: storyBookmarks, handleBookmark } = useBookmarks(processedStories, 'articles');

  const handleShare = async (story) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: story.title,
          text: story.description || story.snippet,
          url: story.link
        });
      } else {
        await navigator.clipboard.writeText(story.link);
        console.log('Link copied to clipboard');
      }
    } catch (error) {
      console.error('Error sharing story:', error);
    }
  };

  if (!topStories.length && !isLoading) return null;

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4 text-black">Top Stories</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {isLoading ? (
          Array(4).fill(0).map((_, index) => (
            <ArticleCardSkeleton key={`skeleton-${index}`} />
          ))
        ) : processedStories.length > 0 ? (
          getSortedContent(processedStories, sortOrder).map((story) => (
            <ArticleCard 
              key={story.id} 
              article={story}
              onBookmark={() => handleBookmark(story)}
              onShare={() => handleShare(story)}
              isBookmarked={storyBookmarks[story.id]}
            />
          ))
        ) : null}
      </div>
    </div>
  );
};

export default TopStoriesSection;