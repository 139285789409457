import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from '../../cards';
import { selectPeopleAlsoAsk } from '../../../redux/store';

const PeopleAlsoAskSection = () => {
  const peopleAlsoAsk = useSelector(selectPeopleAlsoAsk);
  
  if (!peopleAlsoAsk?.length) return null;

  return (
    <div className="mb-8">
      <h2 className="text-2xl font-bold mb-4">People Also Ask</h2>
      <div className="space-y-4">
        {peopleAlsoAsk.map((item, index) => (
          <Card
            key={index}
            type="peopleAlsoAsk"
            data={item}
          />
        ))}
      </div>
    </div>
  );
};

export default PeopleAlsoAskSection;